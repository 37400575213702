import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    this.element[this.identifier] = this;
  }

  closeDropdown(event) {
    if (event) {
      if (event.type == 'keyup' && event.which != 27) {
        return;
      }
      if (event.type == 'click' && $(event.target).closest(this.element).length) {
        return;
      }
    }

    $(this.element).attr('data-active', false);
  }

  toggleDropdown(event) {
    const active = $(this.element).attr('data-active') == 'true';
    $(this.element).attr('data-active', !active);
  }
}
