import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["text", "copyIcon"];

  copy(event) {
    const text = this.textTarget;
    const copyIcon = this.copyIconTarget.firstElementChild; // Select the <i> element
    navigator.clipboard.writeText(text.value)
      .then(() => {
        const iconParent = copyIcon.parentNode; // Get the parent node of the icon
        iconParent.removeChild(copyIcon); // Remove the icon from the parent node
        iconParent.innerHTML += '<i class="fa-duotone fa-check text-success"></i>'; // Add the new icon back

        setTimeout(() => {
          const newCopyIcon = iconParent.querySelector(".fa-check"); // Select the new icon
          iconParent.removeChild(newCopyIcon); // Remove the new icon
          iconParent.innerHTML += '<i class="fa-duotone fa-clipboard gap-1"></i>'; // Add the original icon back
        }, 2000); // Reset the icon after 2 seconds
      })
      .catch((error) => {
        console.error("Error copying link to clipboard:", error);
      });
  }
}
