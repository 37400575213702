import { Controller } from '@hotwired/stimulus';
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule';

export default class extends Controller {
  connect() {
    if(this.element.dataset.rrule !== "") {
      const rrule = RRule.fromString(this.element.dataset.rrule)
      const words = rrule.toText().split(" ");
      this.element.innerHTML = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
    }
  }
}
