import _ from 'lodash';
import { Controller } from '@hotwired/stimulus';
import numeral from 'numeral';

export default class extends Controller {
  static targets = [
    'table', 'tableCtr', 'total', 'averageNetIncome', 'averageRevenue',
    'departmentToggle', 'departmentAllToggle', 'averageRevenueSummary', 'averageNetIncomeSummary',
    'averageNetIncomeText', 'column'
  ];

  connect() {
    if(this.element.dataset.backendPrefix) {
      this.backendPrefix = this.element.dataset.backendPrefix;
    } else {
      this.backendPrefix = '/';
    }
    this.request();
  }

  calculateSummaries(_event) {
    let format;
    let allDepartmentsEnabled = true;
    const enabledDepartments = $(this.departmentToggleTargets).map((_i, toggle) => {
      if (toggle.checked) { return 1; }

      allDepartmentsEnabled = false;
      return 0;
    });

    $(this.departmentAllToggleTarget).prop('checked', allDepartmentsEnabled);

    const last12MonthsNetIncome = [];
    const last12MonthsRevenue = [];

    this.revenue.forEach((monthRevenue, index) => {
      var countArray = this.departments.map((dept, deptIndex) => {
        return enabledDepartments[deptIndex] ? dept.months[index].count : 0
      })

      var sum_reduce = (total, count) => total + count;
      var total = [countArray.reduce(sum_reduce, 0)];

      this.totalTargets[index].innerHTML = total;

      var netIncomePerHeadcount = this.netIncome[index].y / total;
      var revenuePerHeadcount = monthRevenue.y / total;
      format = revenuePerHeadcount >= 1000 ? '$0.0a' : '$0a';

      if (isFinite(netIncomePerHeadcount)) {
        last12MonthsNetIncome.push(netIncomePerHeadcount);
        this.averageNetIncomeTargets[index].innerHTML = numeral(netIncomePerHeadcount).format(format);
      } else {
        this.averageNetIncomeTargets[index].innerHTML = '&mdash;';
      }


      if (isFinite(revenuePerHeadcount)) {
        last12MonthsRevenue.push(revenuePerHeadcount);
        this.averageRevenueTargets[index].innerHTML = numeral(revenuePerHeadcount).format(format);
      } else {
        this.averageRevenueTargets[index].innerHTML = '&mdash;';
      }
    });

    const last12NetIncomeAverage = _.sum(last12MonthsNetIncome) / last12MonthsNetIncome.length;
    const last12RevenueAverage = _.sum(last12MonthsRevenue) / last12MonthsRevenue.length;

    this.averageNetIncomeSummaryTarget.innerHTML = numeral(last12NetIncomeAverage).format('$0,000');

    if (last12NetIncomeAverage < 0) {
      $(this.averageNetIncomeTextTarget).removeClass('text-primary');
      $(this.averageNetIncomeSummaryTarget).removeClass('text-primary');
      $(this.averageNetIncomeTextTarget).addClass('text-danger');
      $(this.averageNetIncomeSummaryTarget).addClass('text-danger');
    }

    return this.averageRevenueSummaryTarget.innerHTML = numeral(last12RevenueAverage).format('$0,000');
  }

  toggleAllDepartments(event) {
    $(this.departmentToggleTargets).prop('checked', event.target.checked);
    return this.calculateSummaries();
  }

  request() {
    const basePath = `${this.backendPrefix}payroll_analytics/charts`;
    fetch(`${basePath}/headcount_by_department`, {
      headers: {
        'Accept': 'application/json',
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      this.tableCtrTarget.innerHTML = data.html;
      this.netIncome = data.net_income;
      this.revenue = data.revenue;
      this.departments = data.departments;
      return this.calculateSummaries();
    })
    .catch(error => console.error('There has been a problem with your fetch operation:', error));
  }


  selectRow(event) {
    if (event.target.closest('input')) { return; }

    const checkBox = $(event.target).closest('tr').find('input');
    checkBox.prop('checked', !checkBox.prop('checked'));
    return this.calculateSummaries();
  }
}
