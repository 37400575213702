import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  closeModal() {
    const element = document.getElementById('updated-modal')
    const modal = bootstrap.Modal.getInstance(element)
    modal.hide()
  }

  closeOffcanvas() {
    const element = document.getElementById('updated-offcanvas')
    const offcanvas = bootstrap.Offcanvas.getInstance(element)
    offcanvas.hide()
  }
}
