import 'chartjs-adapter-luxon';
import { DateTime } from 'luxon';
import Chart from 'chart.js/auto';
import ChartStyles from '@/utils/chart_styles';
import numeral from 'numeral';
import { Controller } from '@hotwired/stimulus';
import {
  afterBuildTicksFunc,
  colorFunc,
  getTimezoneOffset,
  sizeFunc,
  tooltipLabelCallback,
  tooltipTitleCallback,
  weightFunc
} from '@/utils/chart_helpers';

export default class extends Controller {
  static targets = ['totalCostsChart', 'costsByDepartmentChart', 'timeFrames', 'interval'];

  connect() {
    this.timezoneOffset = getTimezoneOffset();

    if(this.element.dataset.backendPrefix) {
      this.backendPrefix = this.element.dataset.backendPrefix;
    } else {
      this.backendPrefix = '/';
    }

    if (!document.documentElement.hasAttribute('data-turbo-preview')) {
      this.requestChartUpdate();
      return;
    }
  }

  disconnect() {
    if (this.departmentChart) {
      this.departmentChart.destroy();
    }

    if (this.totalsChart) {
      this.totalsChart.destroy();
    }
  }

  buildCostsByDepartmentChart(response) {
    const data = {
      datasets: [{
        data: [],
        backgroundColor: [],
        borderWidth: []
      }],
      labels: []
    };

    response.forEach((dataset, index) => {
      data.datasets[0].data.push(dataset.amount);
      data.datasets[0].backgroundColor.push(ChartStyles.colorLists.blueToGreen[index]);
      data.datasets[0].borderWidth.push(0);
      data.labels.push(dataset.name);
    });

    const options = {
      cutoutPercentage: 60,
      borderWidth: 0,
      maintainAspectRatio: false,
      plugins: {
        doughnutlabel: {
          labels: [
            {
              text: ({ config: { data: { datasets }}}) => {
                if (!datasets[0].data.length) { return '$0'; }
                let total = datasets[0].data.reduce((a, b) => a + b)
                return numeral(total).format('$0[.]0a')
              },
              font: {
                size: 30
              },
              color: ChartStyles.colors.darkBlue
            },
            {
              text: 'Total Cost',
              font: {
                size: 14
              },
              color: ChartStyles.colors.blueGray
            }
          ]
        },
        crosshair: false,
        legend: {
          labels: {
            boxWidth: 12,
            padding: 20
          },
          onClick: false,
          position: 'bottom',
        },
        tooltip: {
          backgroundColor: ChartStyles.tooltips.backgroundColor,
          bodyColor: ChartStyles.tooltips.bodyFontColor,
          bodyFont: {
            size: 16,
          },
          bodySpacing: ChartStyles.tooltips.bodySpacing,
          borderColor: ChartStyles.tooltips.borderColor,
          titleColor: ChartStyles.tooltips.titleFontColor,
          titleMarginBottom: ChartStyles.tooltips.titleMarginBottom,
          padding: {
            x: ChartStyles.tooltips.padding * 0.75,
            y: ChartStyles.tooltips.padding * 0.75,
          },
          displayColors: false,
          callbacks: {
            label: ({ raw, dataset: { data } }) => {
              const amount = raw;
              const total = data.reduce((a, b) => a + b);
              const amountText = numeral(amount).format('$0,0');
              const percentText = numeral(amount / total).format('0.0%');
              return `${amountText} (${percentText})`;
            }
          }
        }
      }
    };

    if (this.departmentChart) {
      this.departmentChart.data = data;
      this.departmentChart.update();
      return;
    }

    this.departmentChart = new Chart(this.costsByDepartmentChartTarget, {
      type: 'doughnut',
      data,
      options
    });
  }

  buildTotalCostsChart(response) {
    const chartColors = ChartStyles.colorLists.purpleToRed;
    let interval = response.interval === 'check_date' ? 'day' : response.interval;

    const chartData = { datasets: [], labels: [] };

    response.data.forEach((dataset, i) => {
      chartData.datasets[i] = {
        label: dataset.name,
        backgroundColor: chartColors[i],
        data: dataset.points.map((point, _i) => ({
          x: DateTime.fromISO(point.x).setZone(this.timezoneOffset),
          y: point.y
        })),
        padding: 0,
        pointBackgroundColor: chartColors[i],
        pointBorderWidth: 0,
        pointHoverBackgroundColor: chartColors[i],
        pointHoverBorderWidth: 0,
        pointHoverRadius: 4,
        pointRadius: 0
      };
    });

    if (this.totalsChart) {
      this.totalsChart.destroy();
    }

    this.totalsChart = new Chart(this.totalCostsChartTarget, {
      type: 'bar',
      data: chartData,
      options: {
        layout: {
          padding: 5
        },
        maintainAspectRatio: false,
        hover: {
          mode: 'index',
          intersect: false
        },
        animation: {
          duration: 300
        },
        onHover: (_mouseEvent, chartElement) => {
          if (!chartElement || !chartElement[0]) { return; }
          return chartElement[0]._index;
        },
        scales: {
          x: {
            distribution: 'series',
            stacked: true,
            type: 'time',
            time: {
              displayFormats: {
                month: 'MMM',
                quarter: 'Qq',
                day: 'M/d'
              },
              unit: interval,
              round: interval,
            },
            adapters: {
              date: {
                zone: this.timezoneOffset
              }
            },
            grid: {
              offset: false,
              drawOnChartArea: false,
              drawBorder: true
            },
            ticks: {
              source: 'data',
              color: colorFunc,
              major: {
                enabled: interval != 'day',
              },
              font: {
                weight: weightFunc,
                size: sizeFunc
              },
              maxRotation: 0,
            },
            afterBuildTicks: afterBuildTicksFunc
          },
          y: {
            border: {
              display: false
            },
            stacked: true,
            grid: {
              display: false
            },
            position: 'left',
            ticks: {
              color: ChartStyles.axes.ticks.color,
              font: {
                size: ChartStyles.axes.ticks.size
              },
              precision: 0,
              callback(value) {
                return numeral(value).format('$0,0[.]00a');
              }
            }
          }
        },
        plugins: {
          crosshair: false,
          legend: {
            position: 'bottom',
            labels: {
              boxWidth: 12,
              padding: 20
            }
          },
          tooltip: {
            axis: 'x',
            backgroundColor: ChartStyles.tooltips.backgroundColor,
            bodyColor: ChartStyles.tooltips.bodyFontColor,
            bodySpacing: ChartStyles.tooltips.bodySpacing,
            borderColor: ChartStyles.tooltips.borderColor,
            intersect: false,
            mode: 'index',
            position: 'nearest',
            titleColor: ChartStyles.tooltips.titleFontColor,
            titleMarginBottom: ChartStyles.tooltips.titleMarginBottom,
            padding: {
              x: ChartStyles.tooltips.padding,
              y: ChartStyles.tooltips.padding,
            },
            footerFont: {
              size: 16
            },
            footerAlign: 'center',
            footerMarginTop: 20,
            callbacks: {
              title: tooltipTitleCallback,
              label: tooltipLabelCallback,
              footer: (tooltipItems) => {
                const total = (tooltipItems.map(i => i.raw.y)).reduce((t, i) => t + i);
                return `Total: ${numeral(total).format('$0,0')}`;
              }
            }
          }
        }
      }
    });
  }

  requestChartUpdate({ target } = {}) {
    let params = new URLSearchParams();

    if (target === this.intervalTarget) {
      params.append('i', this.intervalTarget.value);
    } else if (target === this.timeFramesTarget) {
      params.append('tf', this.timeFramesTarget.value);
    }

    const basePath = `${this.backendPrefix}payroll_analytics/charts`;

    // Fetch total payroll costs
    fetch(`${basePath}/total_payroll_costs?${params.toString()}`, {
      headers: {
        'Accept': 'application/json',
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok for total payroll costs');
      }
      return response.json();
    })
    .then(data => {
      this.buildTotalCostsChart(data);
    })
    .catch(error => console.error('Fetch error for total payroll costs:', error));

    // Fetch payroll costs by department
    fetch(`${basePath}/payroll_costs_by_department?${params.toString()}`, {
      headers: {
        'Accept': 'application/json', 
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok for payroll costs by department');
      }
      return response.json();
    })
    .then(data => {
      this.buildCostsByDepartmentChart(data);
    })
    .catch(error => console.error('Fetch error for payroll costs by department:', error));
  }
};
