// Updates a tax's tax_organizer_sent in the tax table and offcanvas view.
// Updates a tax's preparer in the tax table and offcanvas view.
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  handleUpdate(event) {
    event.preventDefault()
    const url = event.target.dataset.url
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    if (event.target.dataset.confirmation && !confirm(event.target.dataset.confirmation)) { return; }
    else {
      fetch(url, {
        method: event.target.dataset.method || 'PUT',
        headers: {
          'X-CSRF-Token': csrfToken,
          "Content-Type": 'text/vnd.turbo-stream.html',
          'Accept': "text/vnd.turbo-stream.html"
        }})
        .then(response => response.text())
        .then(html => Turbo.renderStreamMessage(html))
    }
  }
}

