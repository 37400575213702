import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  autoSubmit(event) {
    this.element.requestSubmit();
  }

  submitOnEnter(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault()
      this.autoSubmit();
    }
  }

  delayedSubmit(event) {
    if (this.timeout) {
      console.log("Clearing previous timeout")
      clearTimeout(this.timeout)
    }

    this.timeout = setTimeout(() => {
      console.log("Submitting form")
      this.element.requestSubmit()
    }, 3000)
  }

}
