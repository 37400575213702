import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['item'];

  connect() {}

  handleDragstart(event) {
    const dt = event.target.dataTransfer;
    event.dataTransfer.setData('item-id', event.target.dataset.itemId);
    event.dataTransfer.setData('item-move-path', event.target.dataset.itemMovePath);
  }

  handleDragenter(event) {
    const target = $(event.target).closest(this.itemTargets)[0]
    if (target.dataset.itemType === 'folder-open') {
      target.classList.add('opacity-50')
    }
  }

  handleDragover(event) {
    event.preventDefault()
  }

  handleDragend(event) {
    event.preventDefault()
  }

  handleDragleave(event) {
    const target = $(event.target).closest(this.itemTargets)[0]
    target.classList.remove('opacity-50')
    event.preventDefault()
  }

  handleDrop(event) {
    const dropItem = $(event.target).closest(this.itemTargets)[0]
    dropItem.classList.remove('opacity-50')

    const dragItemId = event.dataTransfer.getData('item-id');
    const dragItemPath = event.dataTransfer.getData('item-move-path');
    const dropItemType = dropItem.dataset.itemType;
    const parentId = dropItem.dataset.itemId;
    if (dropItemType !== 'folder-open') {
      this.createToast({color: 'warning', message: 'You cannot drag / drop onto a file'});
    }

    if (dragItemId === parentId) {
      return;
    }
    $.ajax({
      url: dragItemPath,
      type: 'PATCH',
      data: {parent_id: parentId}
    }).done((response) => {
      this.createToast({color: response.color, message: response.message});
      document.querySelector(`[data-item-id="${dragItemId}"]`).style.display = 'none';
    })
  }

  handleDragend(event) {
    event.preventDefault()
  }

  createToast({color, message}) {
    const toastContainer = document.getElementsByClassName('toast-container')[0]
    toastContainer.innerHTML += this.buildToast(color, message)
  }

  buildToast(color, message) {
    return `
      <div class="toast show align-items-center text-white bg-${color} border-0 mb-4" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div class="toast-body">
            ${message}
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    `
  }
}
