import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect() { 
    this.form = this.element
    this.form.addEventListener("change", this.onFilterChange.bind(this))
  }

  onFilterChange(event) {
    event.target.form.requestSubmit()
  }
}
