// Sorts items in a checklist
// It sorts two different ways:
//
// for the frontend method, it's sorting
// on the edit / new (since we don't want to automatically push an update as their editing)
///
// for the backend, it's sorting
// on the show
import { Controller } from "@hotwired/stimulus"
import { Sortable } from 'sortablejs'

export default class extends Controller {
  static targets = ['checklist']

  connect() {
    new Sortable(this.checklistTarget, {
      animation: 150,
      chosenClass: 'cursor-grabbing',
      onEnd: (event) => { this.updatePositions(event) }
    });
  }

  updatePositions(event) {
    if (event.item.dataset.url) {
      this.updatePositionsThroughBackend(event)
    } else {
      this.updatePositionsThroughFrontend()
    }
  }

  updatePositionsThroughBackend(event) {
    const data = { position: event.newIndex + 1 }
    fetch(event.item.dataset.url, {
      method: 'PATCH',
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    })
  }

  updatePositionsThroughFrontend() {
    const items = this.checklistTarget.querySelectorAll('.nested-fields')
    items.forEach((id, index) => {
      const item = items[index]
      const input = item.querySelector('input[name*="[position]"]')
      input.value = parseInt(index) + 1
    })
  }
}
