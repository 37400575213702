const Utilities = {
  opacitize: (color, value = 1) => {
    if (typeof value != 'number' && typeof value != 'string') {
      return color;
    } else {
      if (parseFloat(value) > 1) {
        value /= 100;
      } else if (parseFloat(value) < 0) {
        value = 0;
      }
      return color.replace(/[^,]+(?=\))/, value);
    }
  },

  redirect: (url) => {
    if (url.indexOf('#') == -1) {
      Turbo.visit(url);
    } else {
      window.location.href = url;
    }
  },

  smoothScroll: (to) => {
    if ($(to).length) {
      const elementOffset = $(to).offset().top;
      $('html, body').animate({ scrollTop: elementOffset }, 1000, 'swing');
    }
  }
};

export default Utilities;
