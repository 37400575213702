import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static targets = ['modal']

  connect() {
    this.modal = new bootstrap.Modal(this.modalTarget)
    this.modal.show()
    this.element.addEventListener('turbo:submit-end', (event) => {
      if (event.detail.success) {
        this.close();
      }
    })
  }

  open() {
    this.modal.show()
  }

  close(event) {
    this.modal.hide()
  }
}
