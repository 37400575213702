import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

export default class extends Controller {
  connect() {
    this.sortable = new Sortable(this.element, {
      animation: 150,
      handle: ".drag-handle",
      onEnd: this.handleDrop.bind(this)
    });
  }

  handleDrop(event) {
    let noteId = event.item.getAttribute("data-note-id");
    let accountId = event.item.getAttribute("data-account-id");
    let position = event.newIndex + 1;

    fetch(`/admin/accounts/${accountId}/notes/${noteId}/update_position`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ position: position })
    })

  }
}

