import { Controller } from "@hotwired/stimulus"
import Dropzone from "dropzone"
import { Turbo } from "@hotwired/turbo-rails"

Dropzone.autoDiscover = false

export default class extends Controller {
  static targets = ["input", "preview", "form", "dropzone", 'attachments', 'fileSelect']
  static values = {
    maxFilesize: { type: Number, default: 5 },
    acceptedFiles: { type: String, default: ".jpeg,.jpg,.png,.gif,.pdf,.csv,.xlsx,.docx" },
    maxFiles: { type: Number, default: 5 }
  }

  connect() {
    this.initializeDropzone()
  }

  initializeDropzone() {
    this.dropzone = new Dropzone(this.element, {
      url: this.formTarget.action,
      maxFilesize: this.maxFilesizeValue,
      acceptedFiles: this.acceptedFilesValue,
      maxFiles: this.maxFilesValue,
      autoProcessQueue: false,
      addRemoveLinks: false,
      previewsContainer: this.previewTarget,
      previewTemplate: `
        <div class="dz-preview dz-file-preview position-relative">
          <div class="dz-remove-container">
            <a href="#" class="dz-remove" data-dz-remove>
              <i class="far fa-times"></i>
            </a>
          </div>
          <div class="dz-image img-thumbnail" data-bs-toggle="tooltip" data-bs-placement="bottom">
            <img data-dz-thumbnail/>
          </div>
          <div class="dz-details">
            <div class="dz-filename"><span data-dz-name></span></div>
            <div class="dz-size" data-dz-size></div>
          </div>
          <div class="dz-error-message d-none"><span data-dz-errormessage></span></div>
        </div>
      `,
      clickable: this.fileSelectTarget,
      createImageThumbnails: true,
      thumbnailWidth: 80,
      thumbnailHeight: 80,
      init: () => {
        this.element.addEventListener("dragenter", () => this.showDropzone())
        this.element.addEventListener("dragleave", (e) => {
          if (e.target === this.dropzoneTarget) {
            this.hideDropzone()
          }
        })
        this.element.addEventListener("drop", () => this.hideDropzone())
      }
    })

    this.dropzone.on("addedfile", (file) => { this.fileAdded(file) })
    this.dropzone.on("removedfile", (file) => { this.fileRemoved(file) })
    this.dropzone.on("error", (file, errorMessage) => {
      this.dropzone.removeFile(file)
    })
    this.dropzone.on("maxfilesexceeded", (file) => {
      alert(`Cannot add ${file.name}: Maximum number of files exceeded`)
      this.dropzone.removeFile(file)
    })
  }

  showDropzone() {
    this.dropzoneTarget.classList.remove("d-none")
    this.dropzoneTarget.classList.add("d-flex")
  }

  hideDropzone() {
    this.dropzoneTarget.classList.remove("d-flex")
    this.dropzoneTarget.classList.add("d-none")
  }

  fileAdded(file) {
    this.showPreview()
    this.hideDropzone()
  }

  fileRemoved(file) {
    if (this.dropzone.files.length === 0) {
      this.hidePreview()
    }
  }

  updateAttachmentsInput() {
    const attachmentsInput = this.attachmentsTarget
    const files = this.dropzone.files

    const dataTransfer = new DataTransfer()
    files.forEach(file => {
      dataTransfer.items.add(file)
    })

    attachmentsInput.files = dataTransfer.files
  }

  showPreview() {
    this.previewTarget.classList.remove("d-none")
  }

  hidePreview() {
    this.previewTarget.classList.add("d-none")
  }

  submitForm(event) {
    event.preventDefault();
    this.updateAttachmentsInput();
    this.formTarget.requestSubmit();
    this.formTarget.reset();
    this.dropzone.destroy();
    this.initializeDropzone();
  }

  submitOnEnter(event) {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault()
      this.submitForm(event)
    }
  }

  disconnect() {
    this.dropzone.destroy()
  }
}
