import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["formatted", "raw", "formattedDataset", "rawDataset"]

  toggleResponseView(event) {
    event.preventDefault()
    this.formattedTarget.classList.toggle("d-none")
    this.rawTarget.classList.toggle("d-none")

    const button = event.currentTarget
    button.textContent = button.textContent.includes("View Raw") ? "View Formatted" : "View Raw"
    button.setAttribute('aria-expanded', button.getAttribute('aria-expanded') === 'true' ? 'false' : 'true')
  }

  toggleDatasetView(event) {
    event.preventDefault()
    this.formattedDatasetTarget.classList.toggle('d-none')
    this.rawDatasetTarget.classList.toggle('d-none')

    const button = event.currentTarget
    button.textContent = button.textContent.includes("View Raw") ? "View Formatted" : "View Raw"
    button.setAttribute('aria-expanded', button.getAttribute('aria-expanded') === 'true' ? 'false' : 'true')
  }
} 