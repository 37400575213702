import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['alerts'];

  flash({color, message}) {
    const messageContainer = document.getElementsByClassName('dash-system-messages')[0]
    const newAlert = alertElem
    newAlert.classList.add(`alert-${color}`)
    newAlert.innerHTML = message
    newAlert.innerHTML = addCloseButton()
    messageContainer.innerHTML += alert

  }

  createAlert() {
    const alertElem = document.createElement("div");
    alertElem.classList.add('alert', 'alert-dismissible')
    return alertElem
  }

  addCloseButton() {
    const buttonElem = document.createElement("button")
    buttonElem.classList.add('btn-close')
    buttonElem.dataset.bsDismiss="alert"
    buttonElem.ariaLabel="Close"
    return buttonElem
  }
}
