// Makes table rows clickable
// needs a url, turbp_frame as a data attribute

import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  connect() {
    Turbo.visit(this.element.dataset.url, { frame: this.element.dataset.turboFrame })
  }
}
