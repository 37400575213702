// marks support tickets as read once a user opens it

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    url: String
  }

  connect() {
    this.markTicketAsRead()
  }

  async markTicketAsRead() {
    try {
      const response = await fetch(this.element.dataset.url, {
        method: 'PATCH',
        headers: {
          'X-CSRF-Token': this.getMetaValue('csrf-token'),
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'same-origin'
      })

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`)
      }
    } catch (error) {
      console.error('Error marking as read:', error)
    }
  }

  getMetaValue(name) {
    const element = document.head.querySelector(`meta[name="csrf-token"]`)
    return element.getAttribute("content")
  }
}
