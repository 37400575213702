import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ["icon", "counter"]
    static values = { 
        count: Number
    }

    connect() {
        console.debug("Notifications Controller Connected", this.countValue)
        this.indicateNotifications()
    }

    indicateNotifications() {
        if (this.countValue > 0) {
            this.iconTarget.classList.add('fa-shake');
            this.iconTarget.setAttribute('style', '--fa-animation-delay: 1.2s; --fa-animation-iteration-count: 3;')
        } else {
            this.iconTarget.classList.remove('fa-shake');
            this.counterTarget.classList.add('d-none');
        }

        this.counterTarget.innerHTML = this.countValue
    }

}
