import { Controller } from '@hotwired/stimulus'
export default class extends Controller {
  ensureRole(event) {
    let roleInput = document.querySelector("select[name='team_member[role_id]']");
    if (!roleInput.value) {
      event.preventDefault();
      alert("Please select a role.");
      location.reload();
    }
  }
}
