import { Controller } from '@hotwired/stimulus';
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule';

export default class extends Controller {

  static targets = ['dateSummary',
    'dueDate',
    'startDate',
    'workingDays'
  ];

  connect() {
    this.updateDateSummary();
  }

  // creates a summary for the dates selected.
  updateDateSummary() {
    this.dateSummaryTarget.innerHTML = `${this.startDateInWords()} ${this.workingDaysInWords()} ${this.dueDateInWords()}`
  }

  startDateInWords() {
    if(this.startDateTarget.value === "") { return "The task will not have a start date." }
    else if(this.startDateTarget.value !== "") {
      return `The task will start on ${this.formatDate(this.startDateTarget.value)}.`;
    }
  }

  workingDaysInWords() {
    if(this.workingDaysTarget.value !== "" && parseInt(this.workingDaysTarget.value) !== 0) { return `The task will have a total of ${this.workingDaysTarget.value} working days before it is due.` }
    else { return "" }
  }

  dueDateInWords() {
    if(this.dueDateTarget.value === "") { return "The task will not have a due date." }
    else if(this.dueDateTarget.value !== "") {
      return `The task will be due on ${this.formatDate(this.dueDateTarget.value)}.`
    }
  }

  handleStartDate() {
    if(this.startDateTarget.value === "" && this.dueDateTarget.value === "") {
      this.workingDaysTarget.value = 0
      this.workingDaysTarget.disabled = true
    } else if(this.startDateTarget.value !== "") {
      this.workingDaysTarget.disabled = false
      this.calculateWorkingDays()
      this.calculateDueDateFromWorkingDays()
      this.setDueDateMin()
    } else {
      this.dueDateTarget.min = null
      this.workingDaysTarget.value = 0
    }
    this.updateDateSummary()
  }

  setDueDateMin() {
    const result = new Date(this.startDateTarget.value);
    result.setDate(result.getDate() + 1);
    this.dueDateTarget.min = result.toISOString().substring(0, 10);
  }

  handleDueDate() {
    if(this.startDateTarget.value === "" && this.dueDateTarget.value === "") {
      this.workingDaysTarget.value = 0
      this.workingDaysTarget.disabled = true
    } else if(this.dueDateTarget.value !== "") {
      this.calculateWorkingDays()
      this.workingDaysTarget.disabled = false
      this.calculateStartDateFromWorkingDays()
    } else {
      this.workingDaysTarget.value = 0
    }
    this.updateDateSummary()
  }

  // we add the total number of working days to our start date to calculate
  // the due date
  calculateDueDateFromWorkingDays() {
    if(parseInt(this.workingDaysTarget.value) === 0 || this.workingDaysTarget.value === "") { return }
    const result = new Date(this.startDateTarget.value);
    result.setDate(result.getDate() + parseInt(this.workingDaysTarget.value));
    this.dueDateTarget.value = result.toISOString().substring(0, 10);
  }

  // we subtract the total number of working days from our due date to calculate
  // the start date
  calculateStartDateFromWorkingDays() {
    if(parseInt(this.workingDaysTarget.value) === 0 || this.workingDaysTarget.value === "") { return }
    const result = new Date(this.dueDateTarget.value);
    result.setDate(result.getDate() - parseInt(this.workingDaysTarget.value));
    this.startDateTarget.value = result.toISOString().substring(0, 10);
  }

  calculateWorkingDays() {
    if(this.startDateTarget.value === "" || this.dueDateTarget === "") return
    const dueDateObject = new Date(this.dueDateTarget.value);
    const startDateObject = new Date(this.startDateTarget.value);
    const differenceInMilliseconds = Math.abs(dueDateObject - startDateObject);
    this.workingDaysTarget.value = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));
  }

  handleWorkingDays() {
    if(this.workingDaysTarget.value === "" || parseInt(this.workingDaysTarget.value) === 0) {
      this.dueDateTarget.value = null
    } else {
      if(this.startDateTarget.value === "") this.calculateStartDateFromWorkingDays()
      else this.calculateDueDateFromWorkingDays()

      this.setDueDateMin()
    }
    this.updateDateSummary()
  }

  formatDate(date) {
    const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

    // Ensure we're working with the date in local time
    const dateUTC = new Date(date + 'T00:00:00');

    const day = dateUTC.getDate();
    const month = months[dateUTC.getMonth()];
    const year = dateUTC.getFullYear();

    const ordinalSuffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return "st";
        case 2: return "nd";
        case 3: return "rd";
        default: return "th";
      }
    };

    return `${month} ${day}${ordinalSuffix(day)}, ${year}`;
  }

}

