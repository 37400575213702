// my attempt at cocoon
// stolen partially from GoRails and modified
import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "links", "template", 'input', 'container']

  connect() {
    this.wrapperClass = this.data.get("wrapperClass") || "nested-fields"
    document.addEventListener('status-selected', (event) => {this.handleStatusSelected(event) });
  }

  disconnect() {
    document.removeEventListener('status-selected', (event) => {this.handleStatusSelected(event) });
  }

  addAssociation(event) {
    event.preventDefault()

    var content = this.templateTarget.innerHTML.replace(/NEW_RECORD/g, new Date().getTime())
    this.containerTarget.insertAdjacentHTML('beforeend', content)
    const newestInput = this.inputTargets[this.inputTargets.length - 1]
    newestInput.focus()
    const position = newestInput.closest('.nested-fields').querySelector('input[name*="[position]"]')
    if(position) {
      position.value = this.inputTargets.length
    }
    return newestInput
  }

  removeAssociation(event) {
    event.preventDefault()

    let wrapper = event.target.closest("." + this.wrapperClass)

    if (wrapper.dataset.newRecord == "true") {
      wrapper.remove()
    } else {
      wrapper.querySelector("input[name*='_destroy']").value = 1
      wrapper.style.display = 'none'
    }
  }

  handleStatusSelected(event) {
    const selectedValue = event.detail;
    if (selectedValue === 'open_issues') {
      const checklistInput = this.addAssociation(event)
      checklistInput.value = '<strong>[ISSUE]</strong>&nbsp;'
      this.moveCursorToEnd(checklistInput)
    }
  }

  moveCursorToEnd(inputElement) {
    const range = document.createRange();
    const selection = window.getSelection();
    range.setStart(inputElement, inputElement.childNodes.length);
    range.collapse(true);
    selection.removeAllRanges();
    selection.addRange(range);
  }
}
