// Updates the checklist boxes
// There's some special logic here that with the completed that updates in multiple ways:
//
// If we're in a turbo frame, that means we're in a form.
// If we're in the form, instead of sending to the backend, we
// can just toggle the completed value to true / false and wait for the user
// to click the submit button or hit enter.
// if we're not in a turbo frame / form, i.e., we're just
// clicking the checkbox at the view layer instead of editing,
//  let's send a JSON request and update that way
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['checkbox', 'completed']

  toggleCheckBox(event) {
    const completedInput = event.currentTarget.parentNode.querySelector("input[name='checklist_item[completed]']")
    if(event.currentTarget.classList.contains('fa-square')) {
      this.toggleOn(event, completedInput)
    } else {
      this.toggleOff(event, completedInput)
    }
  }

  toggleOn(event, completedInput) {
    event.currentTarget.classList.remove('far', 'fa-square')
    event.currentTarget.classList.add('fas', 'fa-square-check', 'text-primary')
    this.toggleComplete(event, completedInput, true)
  }

  toggleOff(event, completedInput) {
    event.currentTarget.classList.remove('fas', 'fa-square-check', 'text-primary')
    event.currentTarget.classList.add('far', 'fa-square')
    this.toggleComplete(event, completedInput, false)
  }

  toggleComplete(event, completedInput, completed) {
    if(completedInput) {
      completedInput.value = completed
    } else {
      fetch(event.currentTarget.dataset.url, {
        method: 'PUT',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').content,
          'Content-Type': 'application/json',
          'Accept': "text/vnd.turbo-stream.html"
        },
        body: JSON.stringify({checklist_item: {completed: completed}})
      })
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
    }
  }
}
