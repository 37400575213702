import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [ "templateSelector", "contentField" ]

  connect() {
    // anything that needs to happen when the controller connects
  }

  async fetchTemplateContent(event) {
    const templateId = event.target.value
    if (templateId) {
      try {
        const response = await fetch(`/admin/templates/${templateId}.json`)
        const template = await response.json()
        const contentDiv = document.querySelector('trix-editor');
        if(contentDiv) {
          contentDiv.innerHTML = template.content;
        }
      } catch (error) {
        console.error("Error fetching template content:", error)
      }
    }
  }
}

