import { Controller } from '@hotwired/stimulus';
import $ from "jquery";

import Select2 from "select2"

export default class extends Controller {
  static targets = [
    "form",
    "client",
    "service",
    "hoursSpent",
    "activity",
    "notes"
  ];

  initialize() {
    this.billable = false;
    this.formType = "";
  }

  connect() {
    $(this.clientTarget).select2({
        minimumResultsForSearch: 10,
    })
    $(this.clientTarget).on('select2:select', function (e) {
        var data = e.params.data;
        let event = new Event('change', { bubbles: true }) // fire a native event
        this.dispatchEvent(event);
    });
  }

  clientChanged() {
    this.client = this.clientTarget.value;
  }
}
