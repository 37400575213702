import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["searchBox", "tableRows"]

  connect() {
    let searchBox = document.querySelector("input#searchBox");
    searchBox.addEventListener("input", () => {
      this.search()
    })

    let headers = document.querySelectorAll("th[data-column]");
    headers.forEach(header => {
      header.addEventListener("click", () => {
        this.sort(header);
      });
    });
  }

  search() {
    let searchBox = document.querySelector("input#searchBox");
    let tableRows = document.querySelector("tbody#tableRows");
    let query = searchBox.value.toLowerCase();

    tableRows.childNodes.forEach(row => {
      let name = row.querySelector(".name").textContent.toLowerCase()
      let email = row.querySelector(".email").textContent.toLowerCase()
      let role = row.querySelector(".role").textContent.toLowerCase()
      let account = row.querySelector(".account").textContent.toLowerCase()
      let match = name.includes(query) || email.includes(query) || role.includes(query) || account.includes(query)
      row.style.display = match ? "" : "none"
    })
  }

  sort(header) {
    let column = header.dataset.column;
    let sort = header.dataset.sort;
    let tableRows = document.querySelector("tbody#tableRows");
    let rows = Array.from(tableRows.children);

    rows.sort((a, b) => {
      let aValue = a.querySelector(`.${column}`).textContent;
      let bValue = b.querySelector(`.${column}`).textContent;
      if (aValue > bValue) {
        return sort === "asc" ? 1 : -1;
      } else if (aValue < bValue) {
        return sort === "asc" ? -1 : 1;
      } else {
        return 0;
      }
    });

    tableRows.innerHTML = "";
    rows.forEach(row => {
      tableRows.appendChild(row);
    });

    let newSort = sort === "asc" ? "desc" : "asc";
    header.dataset.sort = newSort;
    header.classList.toggle("asc", newSort === "asc");
    header.classList.toggle("desc", newSort === "desc");
  }
}
