import { Controller } from '@hotwired/stimulus'
import Cleave from 'cleave.js'
import { LegalStructureConcern } from "../concerns/legal_structure_concern";

export default class extends Controller {
  static targets = ['collapse']

  connect() {
    Object.assign(this, LegalStructureConcern)
    this.legalStructureChanged = this.legalStructureChanged.bind(this);
    this.legalStructureChanged();
    
    if (this.hasCollapseTarget) {
      this.businessDetails = new bootstrap.Collapse(this.collapseTarget, { toggle: false })
    }
  }

  toggle(event) {
    event.preventDefault()
    if(event.target.textContent.includes('Add additional business details'))
      event.target.textContent = "Nevermind, I'll do this later"
    else
      event.target.textContent = "Add additional business details"
    this.businessDetails.toggle()
  }
}
