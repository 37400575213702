import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static targets = ['collapser', 'icon', 'content', 'name']

  connect() {
    const expanded = window.localStorage.getItem(this.nameTarget.dataset.name)
    this.toggleDetails(expanded == "true")
  }

  toggle() {
    if (!this.hasContentTarget) { return }
    const expanded = this.contentTarget.classList.contains('d-none')
    window.localStorage.setItem(this.nameTarget.dataset.name, expanded)
    this.toggleDetails(expanded)
  }

  toggleDetails(expanded) {
    if(expanded) {
     if(this.hasContentTarget) { this.contentTarget.classList.remove('d-none') }
     this.iconTarget.classList.remove('fad', 'fa-caret-right')
     this.iconTarget.classList.add('fad', 'fa-caret-down')
    } else {
     if(this.hasContentTarget) { this.contentTarget.classList.add('d-none') }
     this.iconTarget.classList.remove('fad', 'fa-caret-down')
     this.iconTarget.classList.add('fad', 'fa-caret-right')
    }

  }
}
