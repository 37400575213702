import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['comment'];

  connect() {
    document.querySelectorAll('input[name^="task[comments_attributes]"]').forEach(input => {
      input.disabled = true
    })
    this.commentTargets.forEach(comment => {
      comment.addEventListener("show.bs.collapse", event => {
        comment.querySelectorAll('input[name^="task[comments_attributes]"]').forEach(input => {
          input.disabled = false
        })
        comment.querySelector('trix-toolbar').style.display = 'none';
        comment.querySelector('trix-editor').value = comment.dataset.originalComment
      })

      comment.addEventListener("hide.bs.collapse", event => {
        comment.querySelectorAll('input[name^="task[comments_attributes]"]').forEach(input => {
          input.disabled = true
        })
        comment.querySelector('trix-editor').value = comment.dataset.originalComment
      })
    })
  }
}
