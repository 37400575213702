import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ["form", "input"];

  connect() {
    this.inputTargets.forEach(input => {
      input.addEventListener("change", () => { this.fetchForm() });
    });
  }

  async fetchForm() {
    const response = await fetch(this.urlWithQueryString(), {
      headers: {
        'Accept': 'text/vnd.turbo-stream.html',
      }
    });
    const html = await response.text();
    Turbo.renderStreamMessage(html);
  }

  urlWithQueryString() {
    return `${this.formTarget.dataset.url}?${this.queryString()}`;
  }

  queryString() {
    const form = new FormData(this.formTarget);
    const params = new URLSearchParams();
    for (const [name, value] of form.entries()) {
      params.append(name, value);
    }

    return params.toString();
  }
}
