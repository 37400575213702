import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["chargeBee"]

  connect() {
    var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'))
    var allowList = bootstrap.Tooltip.Default.allowList;
    allowList.table = ['class'];
    allowList.thead = [];
    allowList.tbody = [];
    allowList.tr = [];
    allowList.th = [];
    allowList.td = [];
    allowList.i = ['class'];
    allowList.svg = ['class'];
    var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
      return new bootstrap.Popover(popoverTriggerEl, { allowList: allowList, html: true, container: 'body' })
    });

  }
}
