import { Controller } from '@hotwired/stimulus';
import ApexCharts from 'apexcharts';

export default class extends Controller {
  static targets = [
    'summaryData',
    'hoursSummary',
    'dollarsSummary',
  ];

  connect() {
    if(this.hasSummaryDataTarget) { this.loadCharts(); }
  }

  loadCharts() {
    var colors = ["#39afd1", "#ffbc00", "#e3eaef"];
    var dataColors = $("#timesheets-stacked-column").data('colors');
    if (dataColors) {
        colors = dataColors.split(",");
    }
    var baseOptions = {
        chart: {
          height: 380,
          type: 'bar',
          stacked: true,
          stackType: '100%',
          toolbar: {
            show: false
          }
        },
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '50%',
            },
        },
        colors: colors,
        fill: {
            opacity: 1
        },
        legend: {
            offsetY: 7,
        },
        grid: {
            row: {
                colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
                opacity: 0.2
            },
            borderColor: '#f1f3fa',
            padding: {
                bottom: 5
            }
        }
    }

    var typeOptions = {...baseOptions,
      colors: ["#536DE6", "#39AFD1"],
      title: { text: 'Total Hours', align: 'center' },
      series: JSON.parse(this.summaryDataTarget.dataset.summaryHours),
      xaxis: {
        labels: {
          show: false,
        },
        categories: JSON.parse(this.summaryDataTarget.dataset.summaryCategories),
      },
    }

    var hoursOptions = {...baseOptions,
      title: { text: 'Client Hours', align: 'center' },
      series: JSON.parse(this.hoursSummaryTarget.dataset.summaryHours),
      xaxis: {
        labels: {
          show: false,
        },
        categories: JSON.parse(this.hoursSummaryTarget.dataset.summaryCategories),
      },
    }

    var dollarsOptions = {...baseOptions,
      title: { text: 'Client Dollars', align: 'center' },
      series: JSON.parse(this.dollarsSummaryTarget.dataset.summaryDollars),
      xaxis: {
        labels: {
          show: false,
        },
        categories: JSON.parse(this.dollarsSummaryTarget.dataset.summaryCategories),
      }
    }

    var typeChart = new ApexCharts(
      document.querySelector("#timesheets-stacked-column"),
      typeOptions
    );

    var hoursChart = new ApexCharts(
      document.querySelector("#timesheets-hours-column"),
      hoursOptions
    );

    var dollarsChart = new ApexCharts(
      document.querySelector("#timesheets-dollars-column"),
      dollarsOptions
    );

    typeChart.render();
    hoursChart.render();
    dollarsChart.render();
  }
}
