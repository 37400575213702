import { Controller } from "@hotwired/stimulus"
import { computePosition, autoUpdate, offset, flip, shift, arrow } from "@floating-ui/dom"

export default class extends Controller {
  static targets = ["card", "content", "arrow"];
  static values = { url: String };

  cleanup = null;

  connect() {
    document.addEventListener('click', this.handleDocumentClick.bind(this));
    this.placement = this.placement || 'bottom'
    this.offset = this.offset || 8
  }

  disconnect() {
    document.removeEventListener('click', this.handleDocumentClick.bind(this));
    if (this.cleanup) {
      this.cleanup();
    }
    if (this.hasCardTarget) {
      this.cardTarget.remove();
    }
  }

  show() {
    if (this.hasCardTarget) {
      this.cardTarget.classList.remove("d-none");
      this.updatePosition();
    } else {
      this.fetchAndAppendCard()
    }
  }

  hide(event) {
    if (this.hasCardTarget) {
      this.cardTarget.classList.add("d-none");
    }
    if (this.cleanup) {
      this.cleanup();
    }
    event.stopPropagation();
  }

  async fetchAndAppendCard() {
    try {
      const response = await fetch(this.urlValue)
      const html = await response.text()
      const fragment = document.createRange().createContextualFragment(html)
      this.element.appendChild(fragment)
      this.updatePosition()
    } catch (error) {
      console.error("Error fetching hovercard content:", error)
    }
  }

  updatePosition() {
    if (this.cleanup) {
      this.cleanup();
    }

    const isElementInViewport = (el) => {
      const rect = el.getBoundingClientRect();
      return (
        rect.top >= 0 &&
        rect.left >= 0 &&
        rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
        rect.right <= (window.innerWidth || document.documentElement.clientWidth)
      );
    };


    const update = () => {
      if (!isElementInViewport(this.element)) {
        this.hide();
        return;
      }
      computePosition(this.element, this.cardTarget, {
        placement: this.placement,
        middleware: [
          offset(this.offset),
          flip(),
          shift(),
          arrow({ element: this.arrowTarget })
        ],
      }).then(({ x, y, placement, middlewareData }) => {
        Object.assign(this.cardTarget.style, {
          left: `${x}px`,
          top: `${y}px`,
        });

        const { x: arrowX, y: arrowY } = middlewareData.arrow;

        const staticSide = {
          top: 'bottom',
          right: 'left',
          bottom: 'top',
          left: 'right',
        }[placement.split('-')[0]];

        Object.assign(this.arrowTarget.style, {
          position: 'absolute',
          left: arrowX != null ? `${arrowX}px` : '',
          top: arrowY != null ? `${arrowY}px` : '',
          right: '',
          bottom: '',
          zIndex: '-1',
          [staticSide]: '-4px',
        });
      });
    };

    this.cleanup = autoUpdate(this.element, this.cardTarget, update);

    window.addEventListener('resize', update);
  }

  handleDocumentClick(event) {
    if (this.hasCardTarget && !this.contentTarget.contains(event.target) && !this.element.contains(event.target)) {
      this.hide();
    }
  }
}
