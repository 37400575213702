import { Controller } from "@hotwired/stimulus";
import { LegalStructureConcern } from "../concerns/legal_structure_concern";

export default class extends Controller {

  constructor() {
    super();
    Object.assign(this, LegalStructureConcern);

    this.legalStructureChanged = this.legalStructureChanged.bind(this);
  }
}

