import { Controller } from '@hotwired/stimulus';
// import $ from "jquery";
export default class extends Controller {
  static targets = [
    "form",
    "formType",
    "hourlyRate",
    "hoursSpent",
    "billableAmount",
    "billable",
    "onlyInternal",
    "onlyClient",
    "onlyBillable",
    "service",
    "account",
    "tags",
    "previouslySelectedService",
    "metered",
    "meteredInfo",
    "billedAt",
    "serviceName"
  ];

  connect() {
    this.accountChanged(true)
    this.changeForm()
    this.totalBillableAmount()
  }

  hourlyRateChanged() {
    if(this.billableTarget.checked) this.totalBillableAmount()
  }

  hoursSpentChanged() {
    if(this.billableTarget.checked) this.totalBillableAmount();
  }

  totalBillableAmount() {
    this.billableAmountTarget.value = (this.hourlyRateTarget.value * this.hoursSpentTarget.value).toFixed(2)
  }

  serviceChanged() {
    if(this.isSelectedServiceBillable()) {
      this.billableTarget.checked = true
      this.hourlyRateTarget.value = (this.service().dataset.billableRate / 100).toFixed(2)
      this.totalBillableAmount();
    } else {
      this.billableTarget.checked = false
    }

    this.setServiceName();
    this.toggleMetered();
    this.toggleBillableTargets();
  }

  setServiceName() {
    const service = this.service()
    if (!service) {return;}
    const serviceName = this.serviceNameTarget
    if(service.value === "") {
      serviceName.value = ""
    } else {
      serviceName.value = service.dataset.name
    }
  }

  isSelectedServiceBillable() {
    const service = this.service()
    if(service.value === "") { return false }
    return service.getAttribute("data-service-billable") == "true";
  }

  overrideBillable() {
    if (this.service() && this.isSelectedServiceBillable() && !this.billableTarget.checked) {
      return confirm("This service is marked as billable. Are you sure you want to proceed?");
    } else { return true }
  }

  service() {
    const selectElement = this.serviceTarget;
    const index = selectElement.selectedIndex;
    return selectElement.options[index];
  }

  billableChanged() {
    if(this.overrideBillable()) {
      this.toggleBillableTargets()
      this.totalBillableAmount();
    }
    this.toggleMetered();
  }

  toggleMetered() {
    if(this.service() != undefined && this.service().dataset.metered === "true") {
      this.meteredTarget.value = true
      this.toggleHourlyRate();
      $(this.meteredInfoTargets).show()
    } else {
      this.meteredTarget.value = false
      this.toggleHourlyRate();
      $(this.meteredInfoTargets).hide()
    }
  }

  toggleHourlyRate() {
    if(this.meteredTarget.value === 'true') {
      this.hourlyRateTarget.readOnly = true
      this.hourlyRateTarget.classList.add('bg-light')
      this.hourlyRateTarget.style.cursor = 'default'
    } else {
      this.hourlyRateTarget.readOnly = false
      this.hourlyRateTarget.classList.remove('bg-light')
      this.hourlyRateTarget.style.cursor = 'text'
    }
  }

  toggleBillableTargets() {
    if(this.billableTarget.checked) $(this.onlyBillableTargets).show();
    else $(this.onlyBillableTargets).hide();
  }

  typeChanged() {
    this.serviceNameTarget.value = null
    this.changeForm()
  }

  changeForm() {
    if (this.formTypeTarget.value == "internal") {
      this.morphForm(this.onlyClientTargets, this.onlyInternalTargets);
      this.accountTarget.disabled = true
      this.serviceTarget.disabled = true
      this.billableTarget.disabled = true
      this.hourlyRateTarget.disabled = true
      this.tagsTarget.disabled = true
    } else {
      this.morphForm(this.onlyInternalTargets, this.onlyClientTargets);
      if(!this.hasBilledAtTarget) { this.accountTarget.disabled = false }
      if(!this.hasBilledAtTarget) { this.serviceTarget.disabled = false;}
      if(!this.hasBilledAtTarget) { this.billableTarget.disabled = false }
      if(!this.hasBilledAtTarget) {
        this.hourlyRateTarget.disabled = false;
        this.toggleHourlyRate()
      }
      if(!this.hasBilledAtTarget) { this.tagsTarget.disabled = false }
      if(this.hasServiceNameTarget) { this.setServiceName() }
      this.toggleBillableTargets();
    }
  }

  accountChanged(initializing = false) {
    const accountId = this.accountTarget.value;
    if (accountId) {
      this.serviceNameTarget.value = null
      const serviceSelect = this.serviceTarget;
      serviceSelect.innerHTML = ""
      serviceSelect.append(new Option("Loading...", ""));
      serviceSelect.disabled = true
      fetch(`/admin/timesheets/${accountId}/billable_services.json`)
        .then(response => response.json())
        .then(data => {
          if(data.length > 0) {
            serviceSelect.innerHTML = ""
            serviceSelect.append(new Option("Select a service...", ""));
            data.forEach(service => {
              let option = new Option(service.name, service.external_item_price_id);
              option.dataset.serviceBillable = service.billable;
              option.dataset.billableRate = service.unit_price;
              option.dataset.metered = service.metered;
              option.dataset.name = service.name;
              serviceSelect.append(option);

            });
            if(this.previouslySelectedServiceTarget.value !== "") {
              serviceSelect.value = this.previouslySelectedServiceTarget.value;
              this.previouslySelectedServiceTarget.value = ""
              this.setServiceName()
            }
          } else {
            serviceSelect.innerHTML = ""
            serviceSelect.append(new Option("No services found", ""));
          }
        });
        if(!this.hasBilledAtTarget) { serviceSelect.disabled = false }
    }
  }

  protected;

  morphForm(hideList, showList) {
    $(hideList).hide();
    $(showList).show();
  }
}
