import { Controller } from '@hotwired/stimulus';
import Utilities from '@/utils/utilities';

export default class extends Controller {
  static targets = ['row', 'collapsableHeader'];

  connect() {
    this.element[this.identifier] = this;
    this.handleCollapsable();
    document.querySelector('tbody').addEventListener('scroll', (e) => {
      const tbody = e.target;
      document.querySelector('thead').style.left = `${-tbody.scrollLeft}px`;
      document.querySelector('thead th:nth-child(1)').style.left = `${tbody.scrollLeft - 5}px`;
      document.querySelectorAll('tbody td:nth-child(1)').forEach((td) => {
        td.style.left = `${tbody.scrollLeft - 5}px`;
      });
    });
  }

  handleCollapsable() {
    const tbodys = this.collapsableHeaderTargets.map(header => header.closest('tbody'));
    tbodys.forEach(tbody => tbody.setAttribute('data-collapsed', true));
  }

  toggleRow(event) {
    const tbody = event.target.closest('tbody');
    const collapsed = tbody.getAttribute('data-collapsed') === 'true';
    tbody.setAttribute('data-collapsed', String(!collapsed));
  }

  // NOTE: This function tries to work in a variety of contexts and heavily relies on jQuery.closest()
  // to function correctly (native closest() cannot be swapped in).
  openURL(event) {
    const target = event.target;
    if (target.closest('.dropdown') || target.closest('select') || target.closest('.td-action-col') || target.closest('a')) {
      return;
    }

    const row = $(target).closest(this.rowTargets[0]);
    const url = row.attr('data-url');

    if (!url) return;

    if (event.which === 1) {
      if (row.attr('data-url-target') === '_blank') {
        window.open(url, '_blank');
      } else {
        Utilities.redirect(url);
      }
    } else if (event.which === 2) {
      window.open(url, '_blank');
    }
  }

  highlightRow(event) {
    this.element.querySelectorAll('td').forEach(td => td.classList.remove('highlight', 'column-highlight'));
    event.target.closest('tr').querySelectorAll('td').forEach(td => td.classList.add('highlight'));
  }

  highlightColumn(event) {
    const index = Array.from(event.target.closest('td, th').parentNode.children).indexOf(event.target.closest('td, th'));
    this.highlightColumnByIndex(index);
  }

  highlightColumnByIndex(index, scrollIntoView = false) {
    this.element.querySelectorAll('th, td').forEach(el => el.classList.remove('highlight', 'column-highlight'));
    const elements = this.element.querySelectorAll(`td:nth-child(${index + 1}), th:nth-child(${index + 1})`);
    elements.forEach(el => el.classList.add('highlight', 'column-highlight'));
  }

  unhighlightColumns() {
    this.element.querySelectorAll('th, td').forEach(el => el.classList.remove('highlight', 'column-highlight'));
  }
}
