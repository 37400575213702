import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['phone'];

  connect() {
    this.phoneTargets.forEach((target) => {
      this.formatPhoneNumberInput(target);
      target.addEventListener('input', () => this.formatPhoneNumberInput(target));
    });
  }

  formatPhoneNumberInput(inputElement) {
    let input = inputElement.value.replace(/\D/g, '');
    let formattedInput = '';

    if (input.length > 0) {
      formattedInput += '(' + input.substring(0, 3);
    }
    if (input.length >= 4) {
      formattedInput += ') ' + input.substring(3, 6);
    }
    if (input.length >= 7) {
      formattedInput += '-' + input.substring(6, 10);
    }

    inputElement.value = formattedInput;
  }
}


