import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {

  connect() {
    this.originalUrl = window.location.href
    this.modal = new bootstrap.Modal(this.element)
    this.element.addEventListener('turbo:frame-load', (event) => {
      this.open()
    })
  }

  open() {
    this.modal.show()
  }

  close(event) {
    event.preventDefault();
    this.modal.hide()
    window.history.pushState({}, '', this.originalUrl);
  }

  backdropClose(event) {
    // Only close if clicking directly on the modal backdrop
    if (event.target === this.element) {
      this.close(event)
    }
  }
}
