import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ["input"];
    static values = {
      minCharacters: Number,
      delay: Number,
      clearOnSubmit: Boolean
    };
  
    connect() {
      this.timeout = null;
      this.delayValue = this.hasDelayValue ? this.delayValue : 500;
      this.clearOnSubmitValue = this.hasClearOnSubmitValue ? this.clearOnSubmitValue : false;
      this.minCharactersValue = this.hasMinCharactersValue ? this.minCharactersValue : 3;
    }
  
    handleInput(_) {
      clearTimeout(this.timeout);
      const query = this.inputTarget.value;
  
      if (query.length >= this.minCharactersValue || query.length === 0) {
        this.timeout = setTimeout(() => {
            this.element.requestSubmit()
        }, this.delayValue);
      }
    }
  
  }
