import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["toggleButton"];

    toggle(event) {
        const closestToggleButton = event.target.closest('.toggle-button');
        if (closestToggleButton) {
            if (this.toggleButtonTargets.length > 1) {
                this.toggleButtonTargets.forEach((element) => {
                    element.classList.remove('active');
                });
                closestToggleButton.classList.add('active');
            } else {
                closestToggleButton.classList.toggle('active');
            }
        }
    }
}
