import { Controller } from '@hotwired/stimulus';
import Sortable from 'sortablejs';

export default class extends Controller {
  static targets = ['sortCtr', 'trend', 'editBtn', 'saveBtn', 'timeFrames', 'interval', 'timeSelects', 'editActions', 'container'];

  connect() {
    this.initSortable();
  }

  initSortable() {
    document.addEventListener("turbo:load", this.initializeIntervalSelect.bind(this));
    this.sortable = new Sortable(this.sortCtrTarget, {
      animation: 150,
      sort: true,
      handle: ".drag-handle", // Adjust the selector to your handle
      onEnd: this.dragEnd.bind(this),
      removeCloneOnHide: true,
      onStart: this.dragStart.bind(this),
      onMove: function (evt) {
        return evt.related.className.indexOf('hidden') === -1; // Prevent sorting over elements with 'hidden' class
      }
    });
  }

  // Turbo drive causes the select2 to 'forget' previous selections
  initializeIntervalSelect(event) {
    const prevSelected = this.intervalTarget.querySelector('option[selected]');
    if (prevSelected) {
      this.intervalTarget.value = prevSelected.value;
      $(this.intervalTarget).select2({minimumResultsForSearch: Infinity}).trigger('change');
    }
  }

  dragStart(evt) {
    this.containerTarget.querySelectorAll('.card').forEach(card => {
      card.classList.add('dragging-item');
    });
    this.containerTarget.querySelectorAll('.trend-info').forEach(card => {
      card.setAttribute('hidden', '');
    });
    this.containerTarget.querySelectorAll('canvas').forEach(card => {
      card.setAttribute('hidden', '');
    });
  }

  edit(event) {
    event.preventDefault();
    this.sortCtrTarget.classList.add('drag-handle');
    this.sortable.option("disabled", false); // Enable sorting
  }

  save(event) {
    this.sortCtrTarget.classList.remove('drag-handle');

    const updateArray = this.trendTargets.map((el, index) => ({
      id: el.dataset.trendId,
      index: index,
      hidden: el.classList.contains('hidden')
    }));

    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    fetch('/financial_metrics/trends/bulk_update', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({ trend: updateArray }),
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
    });
  }

  dragEnd(event) {
    this.containerTarget.querySelectorAll('.card').forEach(card => {
      card.classList.remove('dragging-item');
    });
    this.containerTarget.querySelectorAll('canvas').forEach(card => {
      card.removeAttribute('hidden');
    });
    this.containerTarget.querySelectorAll('.trend-info').forEach(card => {
      card.removeAttribute('hidden');
    });

    setTimeout(() => {
      event.item.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    }, 25); 

    this.save(event);
    const customEvent = new CustomEvent('reinitializeChart', { bubbles: true });
    event.item.dispatchEvent(customEvent);
  }


  toggleCurrentMonth(event) {
    event.preventDefault();
    const showCurrentMonth = !eval(this.data.get('show-current-month'));
    this.data.set('show-current-month', showCurrentMonth);
    this.requestChartUpdate();
  }

  requestChartUpdate(event) {
    let data;
    if (event && event.target === this.intervalTarget) {
      data = { i: this.intervalTarget.value };
    } else if (event && event.target === this.timeFramesTarget) {
      data = { tf: this.timeFramesTarget.value };
    } else {
      data = {};
    }
    let loadTrendsPath = event.target.dataset.trendsPath || '/trends/';
    data.cm = this.data.get('show-current-month');

    $.ajax({
      data: data,
      url: loadTrendsPath,
      success: (response) => {
        this.timeSelectsTarget.innerHTML = response;
      },
    });

    for (const trend of this.trendTargets) {
      if ($(trend).hasClass('hidden')) {
        continue;
      }

      trend['trend'].requestChartData(data, true);
    }
  }
}
