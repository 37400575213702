import {
  afterBuildTicksFunc,
  colorFunc,
  sizeFunc,
  tooltipLabelCallback,
  tooltipTitleCallback,
  weightFunc
} from '@/utils/chart_helpers';
import ChartStyles from '@/utils/chart_styles';
import Utilities from '@/utils/utilities';
import { Controller } from '@hotwired/stimulus';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-luxon';
import { DateTime } from 'luxon';
import numeral from 'numeral';

export default class extends Controller {
  static targets = ['chart'];

  connect() {
    this.timezoneOffset = 0;
    this.buildChart();
  }

  disconnect() {
    this.chart.destroy();
  }

  // Handle time frame change
  changeTimeFrame(event) {
    const value = event.target.value;
    const preferenceType = 'financial_metrics';
    const preferenceKey = 'time_frame';
    
    // Save preference to the backend
    this.savePreference(preferenceType, preferenceKey, value);

    // Update the URL for Turbo navigation
    const url = new URL(window.location);
    url.searchParams.set('tf', value);
    if (url.searchParams.get('i') === 'year' && (value !== 'last_2_years' && value !== 'last_3_years')) {
      url.searchParams.delete('i');
    }
    Turbo.visit(url);
  }

  // Handle interval change
  changeInterval(event) {
    const value = event.target.value;
    const preferenceType = 'financial_metrics';
    const preferenceKey = 'interval';

    // Save preference to the backend
    this.savePreference(preferenceType, preferenceKey, value);

    // Update the URL for Turbo navigation
    const url = new URL(window.location);
    url.searchParams.set('i', value);
    Turbo.visit(url);
  }

  // Save the user preference via an API call to the backend
  savePreference(preferenceType, preferenceKey, value) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    fetch('/user_preferences', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({
        user_preference: {
          preference_type: preferenceType,
          preference_key: preferenceKey,
          preference_value: { value: value }
        }
      })
    })
    .then(response => response.json())
    .then(data => {
      if (!data.success) {
        console.error('Failed to save preference:', data.errors);
      }
    })
    .catch(error => {
      console.error('Error saving preference:', error);
    });
  }

  buildOptions(label, color) {
    return {
      backgroundColor: Utilities.opacitize(color, 0.25),
      borderColor: color,
      fill: '+1',
      pointBackgroundColor: color,
      pointHoverBackgroundColor: color,
      label
    };
  }

  buildData(data) {
    return {
      data: data.map(({ x, y }) => ({
        x: DateTime.fromISO(x).setZone(this.timezoneOffset),
        y: y
      }))
    }
  }

  buildChart() {
    const ctx = $(this.chartTarget);
    const interval = ctx.data('interval');

    const defaultOptions = {
      pointBorderWidth: 0,
      pointHitRadius: 20,
      pointHoverBorderWidth: 0,
      pointHoverRadius: 8,
      pointRadius: 4
    };

    const plotData = {
      datasets: [
        {
          ...defaultOptions,
          ...this.buildData(ctx.data('revenue')),
          ...this.buildOptions('Revenue', ChartStyles.colors.green)
        },
        {
          ...defaultOptions,
          ...this.buildData(ctx.data('grossProfit')),
          ...this.buildOptions('Gross Profit', ChartStyles.colors.blueGray)
        },
        {
          ...defaultOptions,
          ...this.buildData(ctx.data('operatingIncome')),
          ...this.buildOptions('Operating Income', ChartStyles.colors.darkBlue),
          borderDash: [10, 5]
        },
        {
          ...defaultOptions,
          ...this.buildData(ctx.data('netIncome')),
          ...this.buildOptions('Net Income', ChartStyles.colors.blue),
          fill: 'origin'
        }
      ]
    };

    return this.chart = new Chart(ctx, {
      type: 'line',
      data: plotData,
      options: {
        tension: ChartStyles.tension,
        hover: {
          mode: 'index',
          intersect: false,
          onHover: (_event, chartElements) => {
            if (chartElements[0] && $('#overview-table').length) {
              let tableCtrl = $('#overview-table')[0]['table'];
              return tableCtrl.highlightColumnByIndex(chartElements[0]._index + 1);
            }
          }
        },
        animation: {
          duration: 300
        },
        layout: {
          padding: {
            left: 10
          }
        },
        maintainAspectRatio: false,
        scales: {
          x: {
            distribution: 'series',
            type: 'time',
            time: {
              displayFormats: {
                month: 'MMM',
                quarter: 'Qq'
              },
              unit: interval,
              round: interval,
            },
            adapters: {
              date: {
                zone: this.timezoneOffset
              }
            },
            grid: {
              drawOnChartArea: false,
              drawBorder: true
            },
            ticks: {
              major: {
                enabled: true,
              },
              color: colorFunc,
              font: {
                weight: weightFunc,
                size: sizeFunc
              },
              maxRotation: 0,
              maxTicksLimit: 12,
            },
            afterBuildTicks: afterBuildTicksFunc
          },
          y: {
            border: {
              display: false
            },
            grid: {
              display: false
            },
            ticks: {
              color: ChartStyles.axes.ticks.color,
              font: {
                size: ChartStyles.axes.ticks.size
              },
              callback: (value) => (numeral(value).format('$0,0[.]00a'))
            }
          }
        },
        plugins: {
          annotation: {
            annotations: [
              {
                drawTime: 'beforeDatasetsDraw',
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y',
                value: -1,
                borderColor: ChartStyles.colors.blueGrayLight,
                borderWidth: 1,
                borderDash: [10]
              }
            ]
          },
          crosshair: {
            snap: {
              enabled: true
            },
            line: {
              color: ChartStyles.colors.blueGrayLight,
              width: 1,
              dashPattern: [4],
              drawUnderChart: true
            },
            zoom: {
              enabled: false
            }
          },
          legend: {
            position: 'bottom',
            labels: {
              boxWidth: 10,
              padding: 20,
              usePointStyle: true
            }
          },
          tooltip: {
            axis: 'x',
            backgroundColor: ChartStyles.tooltips.backgroundColor,
            bodyColor: ChartStyles.tooltips.bodyFontColor,
            bodySpacing: ChartStyles.tooltips.bodySpacing,
            intersect: false,
            mode: 'index',
            position: 'nearest',
            borderColor: 'rgba(0,0,0,0)',
            titleColor: ChartStyles.tooltips.titleFontColor,
            titleMarginBottom: ChartStyles.tooltips.titleMarginBottom,
            padding: {
              x: ChartStyles.tooltips.padding,
              y: ChartStyles.tooltips.padding,
            },
            callbacks: {
              title: tooltipTitleCallback,
              label: tooltipLabelCallback
            }
          }
        }
      }
    });
  }
}
