import { Controller } from '@hotwired/stimulus'
import 'daterangepicker'
import $ from 'jquery';
import moment from 'moment';

export default class extends Controller {
  get options() {
    let data = $(this.element).data();
    let options = {
      autoUpdateInput: false,
      locale: { cancelLabel: 'Clear'
      },
      alwaysShowCalendars: true,
      autoclose: true,
      ranges: {
          'Today': [moment(), moment()],
          'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
          'Last 7 Days': [moment().subtract(6, 'days'), moment()],

          'This Month': [moment().startOf('month'), moment().endOf('month')],
          'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],

          'This Quarter': [moment().quarter(moment().quarter()).startOf('quarter'), moment().quarter(moment().quarter()).endOf('quarter')],
          'Last Quarter': [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')],

          'This Year': [moment().startOf('year'), moment().endOf('year')],
          'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
          'This YTD': [moment().startOf('year'), moment()],
      }
    };

    for (let item in data) {
      if (item.indexOf('daterangepicker') > -1) {
        options = { ...options, [this.parseOptions(item)]: data[item] };
      }
    }

    return options;
  }

  connect() {
    this.daterangepicker = $(this.element)
      .daterangepicker(this.options)
      .on('apply.daterangepicker', this.onDateChange)
      .on('cancel.daterangepicker', this.onCancel)

    if(this.options.openOnConnect) {
      $(this.element).daterangepicker('show')
    }
  }

  onCancel(e) {
    $(this).val('')
    this.dispatchEvent(new Event('change', { bubbles: true }));
  }

  onDateChange(e, picker) {
    $(this).val(picker.startDate.format('MM/DD/YYYY') + ' - ' + picker.endDate.format('MM/DD/YYYY'))
    this.dispatchEvent(new Event('change', { bubbles: true }));
  }

  parseOptions = (item) => {
    item = item.slice('daterangepicker'.length);
    return item.charAt(0).toLowerCase() + item.slice(1);
  }; 
}
