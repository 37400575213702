const ChartStyles = {
  tension: 0.4,
  axes: {
    ticks: {
      color: 'rgba(180,190,230,1)',
      size: 12,
    }
  },
  colors: {
    blue: 'rgba(38,151,212,1)',
    blueGray: 'rgba(118,126,173,1)',
    blueGrayLight: 'rgba(180,190,230,1)',
    darkBlue: 'rgba(60,65,110,1)',
    darkerBlue: 'rgba(41,41,64,1)',
    indigo: 'rgba(111,86,229,1)',
    indigoLight: 'rgba(111,86,229,0.5)',
    gray: 'rgba(92,91,90,1)',
    green: 'rgba(68,208,148,1)',
    orange: 'rgba(252,106,3,1)',
    purple: 'rgba(189,140,174,1)',
    red: 'rgba(241,83,112,1)',
    yellow: 'rgba(255,196,0,1)',
    white: 'rgba(255,255,255,1)',
    black: 'rgba(0,0,0,1)'
  },
  colorLists: {
    blueToGreen: [
      '#2697d4',
      '#00b7ea',
      '#00d6f8',
      '#00f5ff',
      '#00eadd',
      '#20ddb9',
      '#44d094'
    ],
    purpleToRed: [
      '#6f56e5',
      '#ae74eb',
      '#db99f3',
      '#ffc1ff',
      '#ff9cd8',
      '#fd77a7',
      '#f15370'
    ]
  },
  tooltips: {
    borderColor: 'rgba(0,0,0,0)',
    backgroundColor: 'rgba(41,41,64,1)',
    bodyFontColor: 'rgba(255,255,255,1)',
    bodySpacing: 8,
    padding: 20,
    titleFontColor: 'rgba(180,190,230,1)',
    titleMarginBottom: 15
  }
};

export default ChartStyles;
