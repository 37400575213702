import _ from 'lodash';
import tippy, { delegate } from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-toward.css';
import { Controller } from '@hotwired/stimulus';

// NOTE: Nothing inherits from this currently
// but it's still needed to setup Tippy in a few places.
export default class extends Controller {
  connect() {
    this.setupTippy();
    this.devModeKeySwitch();
    this.disableTrixFileUpload();
  }

  log(level, message) {
    console[level](message);
  }

  warn(message) {
    this.log('warn', message);
  }

  info(message) {
    this.log('info', message);
  }

  error(message) {
    this.log('error', message);
  }

  setupTippy() {
    const navSwitchBp = 500;
    delegate(document.body, {
      target: '[data-tippy-content]',
      animation: 'shift-toward',
      popperOptions: {
        modifiers: [
          {
            name: 'preventOverflow',
            options: {
              boundary: 'viewport'
            }
          }
        ]
      },
      duration: 250,
      theme: 'light-border',
      allowHTML: true,
      onShow: (instance) => {
        return window.innerWidth >= navSwitchBp;
      }
    });
  }

  openModal(event) {
    event.preventDefault();
    const modalId = event.target.closest('a, button').getAttribute('data-modal');
    if (modalId) {
      const modal = new bootstrap.Modal(document.getElementById(modalId));
      modal.show();
    }
  }

  devModeKeySwitch() {
    let keys = [];
    let keyTimeout;
    window.addEventListener('keypress', (e) => {
      clearTimeout(keyTimeout);
      keyTimeout = setTimeout(() => {
        keys = [];
      }, 500);
      keys.push(e.key);

      if (keys.join('') === 'dddddd') {
        App.debugMode = !App.debugMode;
        if (App.debugMode) {
          this.info('Debug Enabled');
        } else {
          this.info('Debug Disabled');
        }
      }
    });
  }

  disableTrixFileUpload() {
    document.addEventListener('trix-file-accept', (event) => {
      event.preventDefault();
    });
  }
}
