import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['statusSelect']

  connect() {
    if(this.hasStatusSelect) { this.previousStatus = this.statusSelectTarget.value }
  }

  // if the status is completed, we want to ask them if they're sure. If so, we
  // continue with the selected value.
  handleStatus(event) {
    if(event.target.value == 'completed' && !confirm(`Are you sure you want to complete this task?`)) {
      this.statusSelectTarget.value = this.previousStatus
    } else if (event.target.value == 'open_issues') {
      const statusSelectedEvent = new CustomEvent('status-selected', { detail: event.target.value })
      document.dispatchEvent(statusSelectedEvent);
    }

  }

  // we have to prefetch the previous status and store it because of a weird
  // decision with how Javascript confirms work. Normally when you click okay
  // or cancel on a confirm, the value that was selected will change regardless.
  // In our case, we want to switch back to the previous (aka current) state if they
  // select cancel.
  handleClick(event) {
    this.previousStatus = event.target.value
  }
}
