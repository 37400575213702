import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [ "logoInput", "logoPreview" ]

  handleSubmit() {
    var hiddenLongDescription = document.querySelector('input[class=partner_long_description]');
    var quillEditor = document.querySelector('div[class=ql-editor]');
    if(quillEditor) {
      hiddenLongDescription.value = quillEditor.innerHTML;
    }
  }

  changeLogo(event) {
    const imagePreview = document.querySelector('img#logoPreview');
    const input = event.target;
    if (input.files && input.files[0]) {
      const reader = new FileReader()
      reader.onload = (e) => {
        imagePreview.src = e.target.result;
      }
      reader.readAsDataURL(input.files[0]);
    }
  }
}
