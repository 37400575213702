import { Controller } from '@hotwired/stimulus';
// import $ from "jquery";

export default class extends Controller {
  static targets = [
    "account",
    "invoice",
    "preview",
    "previouslySelectedInvoice"
  ];

  connect() {
    this.resetPreview();
    if(this.accountTarget.value) { this.fetchInvoices() }
    else { this.fetchInvoice() }
  }

  // When an account changes, we need to fetch all of the invoices
  // for that account.
  fetchInvoices() {
    const accountId = this.accountTarget.value;
    if (accountId) {
      const invoiceSelect = this.invoiceTarget;
      invoiceSelect.innerHTML = ""
      invoiceSelect.append(new Option("Loading...", ""));
      fetch(`/chargebee/invoices.json?account_id=${accountId}`)
        .then(response => {
          if(response.status === 200) {
            return response.json();
          } else {
            invoiceSelect.innerHTML = ""
            invoiceSelect.append(new Option("No invoices found", ""));
          }
        })
        .then(data => {
          if(data !== undefined && data.length > 0) {
            invoiceSelect.innerHTML = ""
            invoiceSelect.append(new Option("Select an invoice...", ""));
            data.forEach(invoice => {
              let option = new Option(`Invoice #${invoice.id} - Total: ${invoice.total}`, invoice.id);
              invoiceSelect.append(option);
            });
            if(this.previouslySelectedInvoiceTarget.value !== "") {
              invoiceSelect.value = this.previouslySelectedInvoiceTarget.value;
              this.previouslySelectedInvoiceTarget.value = ""
              this.fetchInvoice()
            }
            else { this.resetPreview() }
          } else {
            invoiceSelect.innerHTML = ""
            invoiceSelect.append(new Option("No invoices found", ""));
            this.resetPreview()
          }
        });
    }
  }

  // Once an invoice is selected after the account is selected
  // or the invoice has been prepopulated
  // we need to fetch the invoice preview
  // as well as the json to calculate remaining credits
  async fetchInvoice() {
    const invoiceId = this.invoiceTarget.value;
    this.loadingPreview()
    if (invoiceId) {
      fetch(`/chargebee/invoices/${invoiceId}?account_id=${this.accountTarget.value}`,
        { headers: { "Content-Type": 'text/vnd.turbo-stream.html', 'Accept': "text/vnd.turbo-stream.html" } }
      )
      .then(response => response.text())
      .then(html => Turbo.renderStreamMessage(html))
    } else {
      this.resetPreview()
    }
  }

  resetPreview() {
    this.previewTarget.innerHTML = `
      <div class="card h-100 border">
        <div class="h-100 d-flex justify-content-center align-items-center bg-light" >
          Please select an account and an invoice
        </div>
      </div>
    `
  }

  loadingPreview() {
    this.previewTarget.innerHTML = `
      <div class="card h-100 border">
        <div class="h-100 d-flex justify-content-center align-items-center bg-light" >
          <i class="fa fa-spinner fa-spin me-2"></i> Fetching Invoice...
        </div>
      </div>
    `
  }
}
