import { Controller } from '@hotwired/stimulus'
import $ from 'jquery';
import select2 from 'select2'
// Events

// $(this.select).on('select2:select', this.onChange)
// $(this.select).on('select2:unselect', this.onUnselect)
// $(this.select).on('select2:closing', this.onClosing)
// $(this.select).on('select2:close', this.onClose)
// $(this.select).on('select2:opening', this.onOpening)
// $(this.select).on('select2:open', this.onOpen)
// $(this.select).on('select2:selecting', this.onSelecting)
// $(this.select).on('select2:select', this.onSelect)
// $(this.select).on('select2:unselecting', this.onSelecting)
// $(this.select).on('select2:clearing', this.onClearing)
// $(this.select).on('select2:clear', this.onClear)

export default class extends Controller {
  static values = {
    url: String
  }

  get select() {
    return $(this.element);
  }

  get options() {
    let data = $(this.select).data();
    let options = {
      minimumResultsForSearch: 10,
    };

    for (let item in data) {
      if (item.indexOf('select2') > -1) {
        options = { ...options, [this.parseSelect2OptionName(item)]: data[item] };
      }
    }

    return options;
  }

  loadOptions() {
    fetch(this.urlValue)
      .then(response => response.json())
      .then((data) => {
        const selectedValues = this.getSelectedValuesFromURL()
        data.forEach(record => {
          const isSelected = selectedValues.includes(record[1])
          if(!isSelected) {
            let option = new Option(record[0], record[1], false, false);
            this.select.append(option);
          }
        });
        this.select.trigger('change');
      })
      .catch(error => console.error('Error loading options:', error));
  }

  getSelectedValuesFromURL() {
    return new URLSearchParams(window.location.search).toString()
  }

  connect() {

    $(this.select).select2(this.options);

    $(this.select).on('select2:select', this.onChange)
    $(this.select).on('select2:unselect', this.onUnselect)

    if(this.options.openOnFocus) {
      $(this.select).select2('open');
    }

    if (this.hasUrlValue) {
      this.loadOptions();
    }
  }

  disconnect() {
    $(this.select).select2('destroy');
  }

  onChange(e) {
    this.dispatchEvent(new Event('change', { bubbles: true }));
  }

  onUnselect(e) {
    this.dispatchEvent(new Event('change', { bubbles: true }));
  }

  parseSelect2OptionName = (item) => {
    item = item.slice('select2'.length);
    return item.charAt(0).toLowerCase() + item.slice(1);
  };
}
