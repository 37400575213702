import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['accountInput',
                    'titleInput',
                    'returnTypeSelect',
                    'unitPriceInput',
                    'dueDateInput',
                    'statusSelect',
                    'taxBucketInput']

  // handles changing return types based off the account selected.
  // depending on the account, we also populate the return types' options
  // with the ChargebeeItemPrice metadata to set up filtering
  handleAccount(event) {
    this.populateFields()
  }

  populateFields() {

    const url = this.accountInputTarget.options[this.accountInputTarget.selectedIndex].dataset.url

    fetch(url, { headers: { "Content-Type": 'application/json', 'Accept': "application/json" } })
      .then(response => {
        if(response.status === 200) {
          return response.json();
        }
      })
      .then(data => {
        this.returnTypeSelectTarget.innerHTML = ""
        if(data.length === 0) {
          const option = document.createElement('option');
          option.value = ""
          option.textContent = 'No return types found';
          this.returnTypeSelectTarget.append(option);
        } else {
          const option = document.createElement('option');
          option.textContent = 'Please select a return type';
          this.returnTypeSelectTarget.append(option)

          data.forEach(returnType => {
            const option = document.createElement('option');
            option.value = returnType.id;
            option.textContent = returnType.name;
            option.dataset.unitPrice = returnType.unit_price !== null ? (parseFloat(returnType.unit_price)).toFixed(2) : 0.00;
            option.dataset.taxDueDate = returnType.tax_due_date !== null ? returnType.tax_due_date : '';
            option.dataset.taxExtensionDate = returnType.tax_extension_date !== null ? returnType.tax_extension_date : '';
            option.dataset.taxBucket = returnType.tax_bucket !== null ? returnType.tax_bucket : '';
            this.returnTypeSelectTarget.append(option);
          })
        }
      }).then(() => {
        this.populateTaxFields()
      });
  }

  handleReturnType(event) {
    this.populateTaxFields()
  }

  handleStatus(event) {
    if(this.dueDateInputTarget.value !== '' && event.target.value === 'on_extension') { this.populateDueDate() }
  }

  // we have a few hidden fields that are hidden in the background that are meant
  // for filtering. This populates those fields
  populateTaxFields() {
    if(this.hasReturnTypeSelectTarget) {
      const selectedOption = this.returnTypeSelectTarget.options[this.returnTypeSelectTarget.selectedIndex]
      this.titleInputTarget.value = selectedOption.text
      if(selectedOption.dataset.unitPrice === undefined)
        this.unitPriceInputTarget.value = 0.00
      else if (confirm(`Do you want to change the revenue amount to $${(parseFloat(selectedOption.dataset.unitPrice)).toFixed(2)}?`)) {
        this.unitPriceInputTarget.value = (parseFloat(selectedOption.dataset.unitPrice)).toFixed(2)
      }
      this.taxBucketInputTarget.value = selectedOption.dataset.taxBucket != undefined ? selectedOption.dataset.taxBucket : 'main_tax'
      if(selectedOption.dataset.taxDueDate || selectedOption.dataset.taxExtensionDate) {this.populateDueDate() };
    }
  }

  // auto-populates a due_date if the return type has one.
  // if they are selecting on extension status and the return type has
  // an extension date attached, we ask them if they want to change the due date
  // to the extended date
  populateDueDate() {
    if(this.hasReturnTypeSelectTarget) {
      const selectedOption = this.returnTypeSelectTarget.options[this.returnTypeSelectTarget.selectedIndex]
      if(selectedOption.dataset.taxDueDate && this.statusSelectTarget.value !== 'on_extension') {
        this.dueDateInputTarget.value = this.convertDate(selectedOption.dataset.taxDueDate)
        this.dueDateInputTarget.dispatchEvent(new Event('change', { bubbles: true }))
      } else if(selectedOption.dataset.taxExtensionDate && this.statusSelectTarget.value === 'on_extension') {
        if(confirm(`Do you want to change the due date to the extended date (${selectedOption.dataset.taxExtensionDate + '/' + new Date().getFullYear()})?`)) {
          this.dueDateInputTarget.value = this.convertDate(selectedOption.dataset.taxExtensionDate)
          this.dueDateInputTarget.dispatchEvent(new Event('change', { bubbles: true }))
        }
      }
    }
  }


  // takes dates from Chargebee that are 4/15 and converts to a proper
  // date for due date input field
  convertDate(dayMonth) {
    const currentYear = new Date().getFullYear();
    const combinedDate = dayMonth + '/' + currentYear;

    const fullDate = new Date(combinedDate);

    const year = fullDate.getFullYear();
    const month = ('0' + (fullDate.getMonth() + 1)).slice(-2); // Adding 1 because JavaScript months are 0-based
    const day = ('0' + fullDate.getDate()).slice(-2);

    return `${year}-${month}-${day}`;
  }
}
