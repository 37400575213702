import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["input", "alert", "spinner"];

  connect() {
    if (this.data.get('state') === 'visible') {
      this.inputTarget.type = 'text';
    } else {
      this.inputTarget.type = 'password';
    }
    if(this.hasSpinnerTarget) {
      this.clearAlerts();
    }
  }

  toggle() {
    if (this.data.get('state') === 'visible') {
      this.data.set('state', 'hidden');
      this.inputTarget.type = 'password';
      this.clearAlerts();
    } else {
      this.data.set('state', 'visible');
      this.inputTarget.type = 'text';
      this.checkPwned();
    }
  }

  checkPwned() {
    const password = this.inputTarget.value;
    this.showSpinner();

    fetch('/pwned-passwords/check', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({ password: password })
    })
    .then(response => response.json())
    .then(data => {
      this.hideSpinner();
      if (data.breached) {
        this.alertTarget.classList.add('text-danger');
        this.showAlert(data.pwned_message);
      } else {
        this.alertTarget.classList.add('text-success');
        this.showAlert('Congratulations, this password has not been reported in a data breach.');
      }
    })
    .catch(error => {
      this.hideSpinner();
      this.showAlert('Error checking password. Please try again.');
    });
  }

  showSpinner() {
    this.spinnerTarget.style.display = 'inline-block';
  }

  hideSpinner() {
    this.spinnerTarget.style.display = 'none';
  }

  showAlert(message) {
    this.hideSpinner();
    this.alertTarget.style.display = 'inline-block';
    this.alertTarget.innerHTML = message;
  }

  clearAlerts() {
    this.hideSpinner();
    this.alertTarget.innerHTML = "";
    this.alertTarget.style.display = 'none';
  }
}
