import { DateTime } from 'luxon';
import Chart from 'chart.js/auto';
import 'chartjs-adapter-luxon';
import ChartStyles from '@/utils/chart_styles';
import Utilities from '@/utils/utilities';

import numeral from 'numeral';
import { Controller } from '@hotwired/stimulus';
import { getTimezoneOffset } from '@/utils/chart_helpers';

export default class extends Controller {
  static targets = [
    'changePercent', 'currentValue', 'previousValue', 'currentDate',
    'previousDate', 'currentEndDate', 'previousEndDate', 'chart', 'loadingSpinner'
  ];

  connect() {
    this.timezoneOffset = getTimezoneOffset();
    this.element[this.identifier] = this;
    this.type = this.data.get('type');

    this.requestChartData();
  }

  disconnect() {
    this.chart.destroy();
  }

  requestChartData(params = {}) {
    let path = this.data.get('path') || '/metrics/';
    $.ajax({
      dataType: 'json',
      cache: true,
      data: params,
      url: `${path}${this.data.get('id')}`,
      success: (data) => {
        this.currentTotal = data.current_total;
        this.previousTotal = data.previous_total;
        this.currentEndDate = DateTime.fromISO(data.current_points.slice(-1)[0].x).setZone(this.timezoneOffset);
        this.previousEndDate = DateTime.fromISO(data.previous_points.slice(-1)[0].x).setZone(this.timezoneOffset);
        this.buildChart(data);
        this.updateDataElements();
        this.showChart();
      }
    });
  }

  updateDataElements(currentDate, previousDate, currentValue, previousValue) {
    currentValue = currentValue ?? this.currentTotal;
    previousValue = previousValue ?? this.previousTotal;

    let changePercent = Math.abs((currentValue - previousValue) / previousValue);
    changePercent = changePercent * -1;
    if (currentValue < previousValue) {
      changePercent = changePercent * -1;
    }
    if (this.data.get('flip-percent') == 'true') {
      changePercent = changePercent * -1;
    }

    const infiniteChangePercent = [Infinity, -Infinity].includes(changePercent);

    this.currentValueTarget.innerHTML = this.formatNumber(currentValue);
    this.previousValueTarget.innerHTML = this.formatNumber(previousValue);
    this.previousEndDateTarget.innerHTML = this.previousEndDate.toFormat('MMM yy');
    this.currentEndDateTarget.innerHTML = this.currentEndDate.toFormat('MMM yy');

    if (infiniteChangePercent) {
      this.changePercentTarget.innerHTML = '&infin;';
    } else {
      this.changePercentTarget.innerHTML = numeral(changePercent).format('0.0%');
    }

    let badgeColor;
    if (infiniteChangePercent) {
      badgeColor = 'bg-secondary';
    } else if (changePercent > 0) {
      badgeColor = 'bg-success';
    } else if (changePercent < 0) {
      badgeColor = 'bg-danger';
    } else {
      badgeColor = 'bg-secondary';
    }
    this.changePercentTarget.classList = `badge fs-6 ${badgeColor}`;

    if (currentDate && previousDate) {
      this.currentDateTarget.innerHTML = currentDate.toFormat('MMM yy');
      this.previousDateTarget.innerHTML = previousDate.toFormat('MMM yy');
    } else {
      this.currentDateTarget.innerHTML = '';
      this.previousDateTarget.innerHTML = '';
    }
  }

  formatNumber(number) {
    if (this.type == 'dollar') {
      return numeral(number).format('$0,00');
    } else if (this.type == 'percent') {
      return `${numeral(number).format('0.[0]')}%`;
    } else {
      return numeral(number).format('0.[0]');
    }
  }

  buildChart(data) {
    const defaultPlotOptions = {
      padding: 0,
      pointBorderWidth: 0,
      pointHoverBorderWidth: 0,
      pointHoverRadius: 4,
      pointRadius: 0,
      fill: -1
    };

    const currentChartData = {
      data: data.current_points.map((point, i) => ({
        x: i,
        y: point.y,
        date: DateTime.fromISO(point.x).setZone(this.timezoneOffset)
      }))
    };

    const previousChartData = {
      data: data.previous_points.map((point, i) => ({
        x: i,
        y: point.y,
        date: DateTime.fromISO(point.x).setZone(this.timezoneOffset)
      }))
    };

    const currentPlotOptions = {
      backgroundColor: Utilities.opacitize(ChartStyles.colors.blue, 0.25),
      borderColor: ChartStyles.colors.blue,
      pointBackgroundColor: ChartStyles.colors.blue,
      pointHoverBackgroundColor: ChartStyles.colors.blue
    };

    const previousPlotOptions = {
      backgroundColor: Utilities.opacitize(ChartStyles.colors.blueGrayLight, 0.25),
      borderColor: ChartStyles.colors.blueGrayLight,
      pointBackgroundColor: ChartStyles.colors.blueGrayLight,
      pointHoverBackgroundColor: ChartStyles.colors.blueGrayLight
    };

    const currentChart = Object.assign({}, currentChartData, defaultPlotOptions, currentPlotOptions);
    const previousChart = Object.assign({}, previousChartData, defaultPlotOptions, previousPlotOptions);

    const plotData = {
      datasets: [
        currentChart,
        previousChart
      ],
      labels: currentChartData.data.map((point) => point.x)
    };

    if (this.chart) {
      this.chart.data = plotData;
      this.chart.update();
      return;
    }

    this.chart = new Chart(this.chartTarget, {
      type: 'line',
      data: plotData,
      options: {
        maintainAspectRatio: false,
        layout: {
          padding: 5
        },
        hover: {
          mode: 'index',
          intersect: false
        },
        animation: {
          duration: 300
        },
        tension: ChartStyles.tension,
        onHover: (_mouseEvent, chartElement) => {
          if (chartElement && chartElement[0]) {
            const index = chartElement[0].index;
            const currentPoint = this.chart.data.datasets[0].data[index];
            const previousPoint = this.chart.data.datasets[1].data[index];
            this.updateDataElements(
              currentPoint.date,
              previousPoint.date,
              currentPoint.y,
              previousPoint.y
            );
          } else {
            this.updateDataElements(
              this.currentEndDate,
              this.previousEndDate
            );
          }
        },
        scales: {
          x: {
            bounds: 'ticks',
            display: false,
            type: 'category',
            adapters: {
              date: {
                zone: this.timezoneOffset
              }
            }
          },
          y: {
            display: false
          }
        },
        plugins: {
          annotation: {
            annotations: [
              {
                drawTime: 'beforeDatasetsDraw',
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y',
                value: 0,
                borderColor: ChartStyles.colors.blueGrayLight,
                borderWidth: 1
              }
            ]
          },
          legend: {
            display: false
          },
          tooltip: {
            enabled: false
          },
          crosshair: {
            snap: {
              enabled: true
            },
            line: {
              color: ChartStyles.colors.blueGrayLight,
              width: 1,
              dashPattern: [4]
            },
            zoom: {
              enabled: false
            }
          }
        }
      }
    });
  }

  showLoading() {
    this.loadingTimeout = setTimeout(() => {
      this.chartTarget.classList.add('hidden');
      this.loadingSpinnerTarget.classList.remove('hidden');
    }, 500);
  }

  showChart() {
    clearTimeout(this.loadingTimeout);
    this.loadingSpinnerTarget.classList.add('hidden');
    this.chartTarget.classList.remove('hidden');
  }
}
