import { Controller } from '@hotwired/stimulus';

// NOTE: Shared between /accounting/add_accounts and /trends/:id/edit
export default class extends Controller {
  static targets = ['account', 'noResults', 'filter'];

  connect() {
    this.selectedFilter = 'all';
    this.query = '';
  }

  search(event) {
    if (event.which === 13) event.preventDefault();
    this.query = event.target.value.toLowerCase();
    this.filterAccounts();
  }

  changeFilter(event) {
    this.filterTargets.forEach(el => {
      el.setAttribute('data-active', false);
      el.classList.remove('active');
    });
    const el = event.target;
    el.classList.add('active');
    el.setAttribute('data-active', true);
    this.selectedFilter = el.dataset.filter;
    this.filterAccounts();
  }

  filterAccounts() {
    const query = this.query;
    const selectedFilter = this.selectedFilter;

    this.accountTargets.forEach(el => {
      const accountFilter = el.dataset.filter;
      const textContent = el.textContent.toLowerCase();
      if (selectedFilter === 'all' || accountFilter === selectedFilter) {
        if (textContent.search(query) === -1) {
          el.style.display = 'none';
        } else {
          el.style.display = '';
        }
      } else {
        el.style.display = 'none';
      }
    });

    const visibleAccounts = this.accountTargets.filter(el => el.style.display !== 'none');
    if (visibleAccounts.length === 0) {
      this.noResultsTarget.classList.remove('display--none');
    } else {
      this.noResultsTarget.classList.add('display--none');
    }
  }

  selectRow(event) {
    if (event.target.type === 'checkbox') return;
    const checkBox = event.target.closest('tr').querySelector('input[type=checkbox]');
    checkBox.checked = !checkBox.checked;
  }
}
