import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['scrollContent'];

  connect() {
    const config = {
      attributes: true,
      childList: true,
      subtree: true
    };

    const callback = (mutationsList, observer) => {
      for (const mutation of mutationsList) {
        if (mutation.type === 'childList' || mutation.type === 'attributes') {
          this.toggleOverlays();
          break;
        }
      }
    };

    const observer = new MutationObserver(callback);
    observer.observe(this.scrollContentTarget, config);

    window.addEventListener('load', () => {
      this.toggleOverlays();
    });
  }

  toggleOverlays(event) {
    const hasScrollBar = this.scrollContentTarget.scrollHeight > this.scrollContentTarget.clientHeight;
    const atStart = this.scrollContentTarget.scrollTop === 0;
    const atEnd = this.scrollContentTarget.scrollTop === this.scrollContentTarget.scrollHeight - this.scrollContentTarget.offsetHeight;

    this.element.setAttribute('data-has-scroll', hasScrollBar);
    this.element.setAttribute('data-at-start', atStart);
    this.element.setAttribute('data-at-end', atEnd);
  }
}
