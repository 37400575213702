import 'chartjs-adapter-luxon';
import { DateTime } from 'luxon';
import Chart from 'chart.js/auto';
import ChartStyles from '@/utils/chart_styles';
import Utilities from '@/utils/utilities';
import numeral from 'numeral';
import { Controller } from '@hotwired/stimulus';
import {
  afterBuildTicksFunc,
  colorFunc,
  getTimezoneOffset,
  sizeFunc,
  tooltipLabelCallback,
  tooltipTitleCallback,
  weightFunc
} from '@/utils/chart_helpers';

export default class extends Controller {
  static targets = ['chart'];

  connect() {
    if(this.element.dataset.backendPrefix) {
      this.backendPrefix = this.element.dataset.backendPrefix;
    } else {
      this.backendPrefix = '/';
    }
    this.timezoneOffset = getTimezoneOffset();
    this.element[this.identifier] = this;
    this.requestData();
  }

  buildChart(response) {
    const interval = 'month';

    const chartData = {
      datasets: [
        {
          label: 'Revenue',
          backgroundColor: Utilities.opacitize(ChartStyles.colors.green, 0.25),
          borderColor: ChartStyles.colors.green,
          data: response.revenue_points.map((point, _i) => {
            return { x: DateTime.fromISO(point.x).setZone(this.timezoneOffset), y: point.y  };
          }),
          fill: '1+',
          pointBackgroundColor: ChartStyles.colors.green,
          pointHoverBackgroundColor: ChartStyles.colors.green,
          pointBorderWidth: 0,
          pointHitRadius: 20,
          pointHoverBorderWidth: 0,
          pointHoverRadius: 8,
          pointRadius: 4
        },
        {
          label: 'Net Income',
          backgroundColor: Utilities.opacitize(ChartStyles.colors.blue, 0.25),
          borderColor: ChartStyles.colors.blue,
          data: response.net_income_points.map((point, _i) => {
            return { x: DateTime.fromISO(point.x).setZone(this.timezoneOffset), y: point.y  };
          }),
          fill: 'origin',
          pointBackgroundColor: ChartStyles.colors.blue,
          pointHoverBackgroundColor: ChartStyles.colors.blue,
          pointBorderWidth: 0,
          pointHitRadius: 20,
          pointHoverBorderWidth: 0,
          pointHoverRadius: 8,
          pointRadius: 4
        }
      ]
    };

    if (this.chart) {
      this.chart.data = chartData;
      this.chart.options.scales.x.time.unit = interval;
      this.chart.update();
      return;
    }

    this.chart = new Chart(this.chartTarget, {
      type: 'line',
      data: chartData,
      options: {
        maintainAspectRatio: false,
        tension: ChartStyles.tension,
        hover: {
          mode: 'index',
          intersect: false,
          onHover: (_event, chartElements) => {
            if (chartElements[0]) {
              const tableCtrl = $('#headcount-by-revenue-table')[0]['table'];
              return tableCtrl.highlightColumnByIndex(chartElements[0]._index + 1);
            }
          }
        },
        animation: {
          duration: 300
        },
        scales: {
          x: {
            distribution: 'series',
            type: 'time',
            time: {
              displayFormats: {
                month: 'MMM',
                quarter: 'Qq'
              },
              unit: interval,
              round: interval,
            },
            adapters: {
              date: {
                zone: this.timezoneOffset
              }
            },
            grid: {
              drawOnChartArea: false,
              drawBorder: true
            },
            ticks: {
              color: colorFunc,
              font: {
                weight: weightFunc,
                size: sizeFunc
              },
              major: {
                enabled: true
              },
              maxRotation: 0,
            },
            afterBuildTicks: afterBuildTicksFunc
          },
          y: {
            border: {
              display: false
            },
            grid: {
              display: false
            },
            ticks: {
              precision: 0,
              color: ChartStyles.axes.ticks.color,
              callback: (value) => (numeral(value).format('$0,0[.]00a'))
            }
          }
        },
        plugins: {
          crosshair: false,
          legend: {
            position: 'bottom',
            labels: {
              boxWidth: 10,
              padding: 20,
              usePointStyle: true
            }
          },
          annotation: {
            annotations: [
              {
                drawTime: 'beforeDatasetsDraw',
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y',
                value: -1,
                borderColor: ChartStyles.colors.blueGrayLight,
                borderWidth: 1,
                borderDash: [10]
              }
            ]
          },
          tooltip: {
            backgroundColor: ChartStyles.tooltips.backgroundColor,
            bodyColor: ChartStyles.tooltips.bodyFontColor,
            bodySpacing: ChartStyles.tooltips.bodySpacing,
            borderColor: ChartStyles.tooltips.borderColor,
            titleColor: ChartStyles.tooltips.titleFontColor,
            titleMarginBottom: 10,
            padding: {
              x: ChartStyles.tooltips.padding * 0.75,
              y: ChartStyles.tooltips.padding * 0.75,
            },
            intersect: false,
            mode: 'index',
            position: 'nearest',
            callbacks: {
              title: tooltipTitleCallback,
              label: tooltipLabelCallback
            }
          }
        }
      }
    });
  }


  requestData() {
    const basePath = `${this.backendPrefix}payroll_analytics/charts`;

    fetch(`${basePath}/revenue_by_month`, {
      headers: {
        'Accept': 'application/json',
      }
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      return response.json();
    })
    .then(data => {
      this.buildChart(data);
    })
    .catch(error => console.error('Fetch error:', error));
  }

}
