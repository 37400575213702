import { Controller } from '@hotwired/stimulus';
import { Sortable } from 'sortablejs';

export default class extends Controller {
  static targets = ['sortable', 'handle'];

  connect() {
    const options = {
      animation: 150,
      handle: this.data.get('handle'),
      onStart: (event) => {
        this.sortableTarget.classList.add('sortable-drag');
      },
      onEnd: (event) => {
        this.sortableTarget.classList.remove('sortable-drag');
        if (event.newIndex === event.oldIndex) return;

        const movedItem = event.item;
        const dataAttribute = movedItem.closest('ul').getAttribute('data-sortable-data');
        const data = { marketplace_partner: { position: event.newIndex + 1 }};

        fetch(movedItem.getAttribute('data-sortable-path'), {
          method: 'PATCH',
          body: JSON.stringify(data),
          headers: {
            'Content-Type': 'application/json'
          }
        });
      }
    };

    const group = this.data.get('group');
    if (group) {
      options.group = group;
    }

    Sortable.create(this.sortableTarget, options);
  }
}
