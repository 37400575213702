import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["accountSelect", "disableFields"]

  connect() {
    this.toggleFields()
  }

  toggleFields() {
    const selectedAccountId = this.accountSelectTarget.value
    this.disableFieldsTargets.forEach(field => {
      field.disabled = !!selectedAccountId
    })
  }
}