import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['renameModal'];

  connect() {
    this.element[this.identifier] = this;
    new bootstrap.Modal("#renameModal")
  }

  open(event) {
    event.stopPropagation();
    if(event.target.dataset.restrictModification === 'true') {
      alert("You cannot rename this folder since it was created automatically by Bookkeeper360.");
      return;
    }
    bootstrap.Modal.getInstance("#renameModal").show()
    document.documentElement.classList.add('scroll-lock');
    this.hydrateModal();
  }

  hydrateModal() {
    const modal = document.getElementById('renameModal')
    const title = modal.querySelectorAll('.modal-title')[0]
    title.innerHTML = `Renaming ${this.element.dataset.itemName}`
    const form = modal.querySelectorAll('form')[0]
    form.action = this.element.dataset.itemUrl
    const hiddenInput = modal.querySelectorAll('#item_id')[0]
    const nameInput = modal.querySelectorAll('#name')[0]
    hiddenInput.value =this.element.dataset.itemId
    nameInput.value =this.element.dataset.itemName
  }

  close(event) {
    if (event) { event.preventDefault(); }
    hideModal()
    document.documentElement.classList.remove('scroll-lock');
  }

  overlayClick(event) {
    if (this.data.get('closable') === 'true') {  hideModal() }
  }

  hideModal() {
    const renameModal = document.getElementById('renameModal');
    const modal = bootstrap.Modal.getInstance(renameModal);
    modal.hide()
  }

  escClose(event) {
    if (event.keyCode !== 27) { return; }
    if (this.data.get('closable') === 'true') { this.close(); }
  }
}
