import { Controller } from '@hotwired/stimulus';
import { datetime, RRule, RRuleSet, rrulestr } from 'rrule';;

export default class extends Controller {

  static targets = ['recurrenceSelect',
    'recurrenceContainer',
    'endsInputs',
    'weeklyInputs',
    'monthlyInputs',
    'weeklyDay',
    'monthlyDay',
    'rruleSummary',
    'count',
    'until',
    'rrule',
    'workingDays'
  ];

  // when we connect here, we need to check if the rrule has a value.
  // if it does, we need to re-inject the rrule into the form so
  // it updates out UI. If not, we just do a fallback on recurrence
  // (technically we can ignore this but better to be safe than sorry)
  connect() {
    this.updateRruleSummary();
    if(this.rruleTarget.value !== "") {
      this.injectRrule()
    } else if(this.recurrenceSelectTarget.value !== '') {
      this.handleRecurrence()
    }
  }

  // this takes the rrule from our task and injects it back into the form
  // by parsing through and handling the right conditions.
  // Once parsed, it triggers the handleRecurrence function
  // that makes the UI adjustments like selecting the right frequency
  // and showing / hiding certain sections.
  injectRrule() {
    let rruleString = this.rruleTarget.value

    // finds and sets our frequency based off of DAILY, WEEKLY, MONTHLY
    const frequencyMatch = rruleString.match(/FREQ=(\w+)/);
    this.frequency = frequencyMatch ? frequencyMatch[1] : null;

    // finds and sets our count, if found,
    const countMatch = rruleString.match(/COUNT=(\d+)/);
    this.count = countMatch ? parseInt(countMatch[1], 10) : null;

    // finds and sets the number of days if found
    const bydayMatch = rruleString.match(/BYDAY=([^;]+)/);
    this.byday = bydayMatch ? bydayMatch[1].split(',') : null;


    // this sets our selected Sunday - Saturday values when frequency is WEEKLY
    if(this.byday) {
      this.weeklyDayTargets.forEach(day => {
        if(this.byday.includes(day.value)) { day.checked = true }
      })
    }

    // finds and sets the end date of the recurrence if found
    const untilMatch = rruleString.match(/UNTIL=(\d{8}T\d{6}Z)/);
    this.until = untilMatch ? `${untilMatch[1].slice(0, 8)}T${untilMatch[1].slice(9, 15)}Z` : null;

    // finds the day of the month if found.
    // if we have our special occurrences set (1st / 15th or 15th / last of the month)
    // then we fork the road and select our special occurrence dropdown instead

    const byMonthDayMatch = rruleString.match(/BYMONTHDAY=([^;]+)/);
    const monthDaySet = byMonthDayMatch && byMonthDayMatch[1] ? byMonthDayMatch[1].split(',') : null
    this.bymonthday = monthDaySet ? monthDaySet.join(',') : null;

    if(this.bymonthday) {
      Array.from(this.monthlyDayTarget.options).forEach(option => {
        option.selected = monthDaySet.indexOf(option.value) !== -1;
      });
    }

    // this following conditionals are used to re-check the never / on / after
    // radio buttons as well as update their respective values next to
    // it (count is for after and until is for on)
    if(this.count !== null) {
      this.countTarget.disabled = false;
      this.countTarget.value = this.count
      this.countTarget.selected = true
      document.querySelector('input[name="task[ends]"][value="after"]').checked = true
    }
    else if(this.until !== null) {
      this.untilTarget.disabled = false;
      const untilDate = `${untilMatch[1].slice(0, 4)}-${untilMatch[1].slice(4, 6)}-${untilMatch[1].slice(6, 8)}`
      this.untilTarget.value = untilDate;
      document.querySelector('input[name="task[ends]"][value="on"]').checked = true
    }

    // if we have an interval, it means its either a quarterly or semiannual
    // recurrence.
    const intervalMatch = rruleString.match(/INTERVAL=(\d+)/);
    this.interval = intervalMatch ? parseInt(intervalMatch[1], 10) : null;

    // if our recurrence option is quarterly or semiannually
    // we need to set that value in the dropdown
    // if not, let's fall back to the monthly value in the dropdown
    if(this.interval == 3) this.recurrenceSelectTarget.value = 'QUARTERLY'
    else if(this.interval == 6) this.recurrenceSelectTarget.value = 'SEMIANNUALLY'
    else if(this.interval == 12) this.recurrenceSelectTarget.value = 'ANNUALLY'
    else this.recurrenceSelectTarget.value = this.frequency

    this.handleRecurrenceInputs();
    this.updateRule()
  }

  // this handles the updating of the UI for recurrence
  // it first resets all of the properties of this controller
  // then it finds the correct frequency values (DAILY, WEEKLY, MONTHLY)
  // and shows / hides sections and updates UIs accordingly
  // Once done, it sets our hidden rrule and sets the summary text.
  handleRecurrence() {
    this.resetValues()
    this.handleRecurrenceInputs()
    this.updateFrequency()
    if(this.recurrenceSelectTarget.value == 'WEEKLY') { this.updateByWeekDay() }
    if(['MONTHLY', 'QUARTERLY', 'SEMIANNUALLY', 'ANNUALLY'].includes(this.recurrenceSelectTarget.value)) { this.updateByMonthDay() }
    this.updateRule()
  }

  // shows / hides the recurrence inputs based off the frequency values (DAILY, WEEKLY, MONTHLY)
  handleRecurrenceInputs() {
    this.recurrenceContainerTarget.classList.toggle('d-none', this.recurrenceSelectTarget.value === '');
    this.endsInputsTarget.classList.toggle('d-none', this.recurrenceSelectTarget.value === '');
    this.monthlyInputsTarget.classList.toggle('d-none', !['MONTHLY', 'QUARTERLY', 'SEMIANNUALLY', 'ANNUALLY'].includes(this.recurrenceSelectTarget.value));
    this.weeklyInputsTarget.classList.toggle('d-none', this.recurrenceSelectTarget.value !== 'WEEKLY');
  }

  // the form's day selection for the MONTH frequency.
  handleMonthlyDay() {
    this.updateByMonthDay()
    this.updateRule()
  }

  updateFrequency() {
    if(this.recurrenceSelectTarget.value !== "") {
      if(this.recurrenceSelectTarget.value == 'QUARTERLY') {
        this.frequency = 'MONTHLY'
        this.interval = 3
      } else if (this.recurrenceSelectTarget.value == 'SEMIANNUALLY') {
        this.frequency = 'MONTHLY'
        this.interval = 6
      } else if (this.recurrenceSelectTarget.value == 'ANNUALLY') {
        this.frequency = 'MONTHLY'
        this.interval = 12
      }
      else {
        this.frequency = 'MONTHLY'
        this.frequency = this.recurrenceSelectTarget.value
      }
    }
  }

  updateByMonthDay() {
    const days = Array.from(this.monthlyDayTarget.selectedOptions).map(option => option.value)
    this.bymonthday = days.join(',');
  }

  updateByWeekDay() {
    this.byday = this.weeklyDayTargets
      .filter(target => target.checked)
      .map(target => target.value)
  }

  handleWeeklyDays() {
    this.updateByWeekDay()
    this.updateRule();
  }

  resetValues() {
    this.frequency = null;
    this.bymonthday = null;
    this.byday = null;
    this.interval = null;
    if(this.until !== null) { this.count = null }
    else if(this.count !== null) {this.until = null }
  }

  // this formats our rrule for the backend
  updateRule() {
    if(this.recurrenceSelectTarget.value === "") {
      this.rule = ""
      this.rruleTarget.value = ""
    } else {
      let rule_as_string = `FREQ=${this.frequency}`
      if(this.bymonthday !== null && this.bymonthday !== undefined) { rule_as_string += `;BYMONTHDAY=${this.bymonthday}` }
      if(this.byday !== null && this.byday !== undefined && this.byday.length > 0) { rule_as_string += `;BYDAY=${this.byday}` }
      if(this.count !== null && this.count !== undefined) { rule_as_string += `;COUNT=${this.count}` }
      if(this.until !== null && this.until !== undefined) { rule_as_string += `;UNTIL=${this.until}` }
      if(this.interval !== null && this.interval !== undefined) { rule_as_string += `;INTERVAL=${this.interval}` }
      this.rule = RRule.fromString(rule_as_string)
      this.rruleTarget.value = RRule.fromString(rule_as_string)
    }
    this.updateRruleSummary()
  }

  // creates a summary for the rrule
  updateRruleSummary() {
     this.rruleSummaryTarget.innerHTML = `${this.recurrenceInWords()}`;
  }
  // this sets when our recurrence will end
  // either never, by date (on) or until a set number of times (after)
  handleEnds(event) {
    if(event.target.value === 'on') {
      this.handleUntil()
    } else if(event.target.value === 'after') {
      this.handleCount()
    } else {
      this.untilTarget.disabled = true
      this.countTarget.disabled = true
      this.until = null
      this.count = null
      this.updateRule()
    }
  }

  handleCount() {
    this.untilTarget.disabled = true
    this.countTarget.disabled = false
    this.until = null
    if(this.countTarget.value == "") this.countTarget.value = 1
    else if(this.countTarget.value < 0 )this.countTarget.value = 1
    this.count = this.countTarget.value
    this.updateRule()
  }

  handleUntil() {
    this.untilTarget.disabled = false
    this.countTarget.disabled = true
    if(this.untilTarget.value == "") { this.untilTarget.value = new Date().toISOString().substring(0, 10) }
    const localDate = new Date(this.untilTarget.value)
    this.until = this.convertDateToRRuleDate(localDate)
    this.count = null
    this.updateRule()
  }

  // rrule needs to have a specific date format
  convertDateToRRuleDate(date) {
    const year = String(date.getUTCFullYear())
    const month = String(date.getUTCMonth() + 1).padStart(2, '0')
    const day = String(date.getUTCDate()).padStart(2, '0')
    return `${year}${month}${day}T000000Z`
  }

  // wordings for rruleSummary
  recurrenceInWords() {
    if(!this.rule) { return 'The task will only be created once.'; }
    const words = this.rule.toText().split(" ");
    const capitalizedWords = words.map(word => word.charAt(0) + word.slice(1));
    const withWorkingDays = this.workingDaysTarget.value != "0" ? ` On creation, it will have a total of ${this.workingDaysTarget.value} working days from task start date.` : ""
    const finalSentence = `The task will be recurring. It will be created ${capitalizedWords.join(" ")}.${withWorkingDays}`
    return finalSentence
  }
}

