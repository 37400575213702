// Used on the overview page to render the breakdown table near the bottom
import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["primary", "secondary"];

  connect() {
    this.secondaryTargets.forEach((element) => {
      element.style.display = "none";
    });
    this.data.set("toggle", "false");
  }

  toggle(event) {
    if (this.data.get("toggle") == "true") {
      this.data.set("toggle", "false");
      this.primaryTargets.forEach((element) => {
        element.style.display = "";
      });
      this.secondaryTargets.forEach((element) => {
        element.style.display = "none";
      });
    } else {
      this.data.set("toggle", "true");
      this.primaryTargets.forEach((element) => {
        element.style.display = "none";
      });
      this.secondaryTargets.forEach((element) => {
        element.style.display = "";
      });
    }
  }
}
