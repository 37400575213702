import 'chartjs-adapter-luxon';
import { DateTime } from 'luxon';
import Chart from 'chart.js/auto';
import ChartStyles from '@/utils/chart_styles';
import numeral from 'numeral';
import { Controller } from '@hotwired/stimulus';
import {
  afterBuildTicksFunc,
  colorFunc,
  getTimezoneOffset,
  sizeFunc,
  tooltipTitleCallback,
  weightFunc
} from '@/utils/chart_helpers';

export default class extends Controller {
  static targets = [
    'visibilityToggle', 'loadingCtr', 'chart', 'labels', 'label',
    'labelBreakdown', 'accounts', 'list', 'chartTypeToggles'
  ];

  connect() {
    [...this.listTargets].map(list => new bootstrap.Collapse(list))

    this.timezoneOffset = getTimezoneOffset();
    this.element[this.identifier] = this;

    this.chartColors = [
      ChartStyles.colors.blue,
      ChartStyles.colors.green,
      ChartStyles.colors.yellow,
      ChartStyles.colors.indigo,
      ChartStyles.colors.red,
      ChartStyles.colors.blueGray
    ];

    this.element.addEventListener('reinitializeChart', this.reinitializeChart.bind(this));
    // Ensures the visibility checkboxes work after initial page load by binding their
    // state to turbo events
    document.addEventListener("turbo:load", this.initializeVisibilityCheckboxState.bind(this));
    document.addEventListener("turbo:load", this.initializeChartStyleToggles.bind(this));
  }

  initializeVisibilityCheckboxState() {
    const shouldBeChecked = this.visibilityToggleTarget.hasAttribute('checked');
    this.visibilityToggleTarget.checked = shouldBeChecked;
  }

  initializeChartStyleToggles() {
    const shouldBeVisible = this.visibilityToggleTarget.hasAttribute('checked');
    if(shouldBeVisible) {
      this.chartTypeTogglesTarget.style.display = '';
      this.element.querySelector(".card-body").classList.remove("collapse")
    } else {
      this.chartTypeTogglesTarget.style.display = 'none';
      this.element.querySelector(".card-body").classList.add("collapse")
    }
  }

  disconnect() {
    if(this.chart) {
      this.chart.destroy();
    }
  }

  changeChartType(event) {
    const toggleButton = event.target.closest('button');
    const type = toggleButton.getAttribute('data-type');
    const toggleButtons = toggleButton.closest('.toggle-buttons').querySelectorAll('.toggle-button');

    toggleButtons.forEach(btn => btn.classList.remove('active'));
    toggleButton.classList.add('active');

    this.chart.config.type = type;
    this.chart.options.scales.x.stacked = type === 'bar';
    this.chart.options.scales.x.offset = type === 'bar';
    this.chart.options.scales.y.stacked = type === 'bar';

    this.chart.update();
  }

  initialize() {
    this.loadTrendPath = '/financial_metrics/trends/';
    if (this.isVisible()) {
      this.requestChartData();
    }
  }

  isHidden() {
    return !this.isVisible();
  }

  isVisible() {
    // HERE BE DRAGONS!!!
    // return this.visibilityToggleTarget.checked === true;
    return this.visibilityToggleTarget.hasAttribute('checked');
  }

  reinitializeChart(event) {
    if (event.currentTarget.dataset.trendId === this.data.get('id') && this.chart) {
      this.chart.destroy();
      this.chart = undefined;
      this.requestChartData();
    }
  }

  buildChart(response) {
    const chartData = { datasets: [], labels: [] };

    // Ugly hack as I can't seem to get the time scale to refresh
    // when toggling between monthy/quarter/year via Turbo drive.
    // This means it stays in "Quarter" mode when requesting month data, or vice-versa.
    this.chart?.destroy();

    response.data.forEach((dataset, i) => {
      chartData.datasets[i] = {
        backgroundColor: this.chartColors[i],
        borderColor: this.chartColors[i],
        data: dataset.points.map(({ x, y }) => ({
          x: DateTime.fromISO(x).setZone(this.timezoneOffset),
          y: y
        })),
        fill: false,
        padding: 0,
        pointBackgroundColor: this.chartColors[i],
        pointBorderWidth: 0,
        pointHoverBackgroundColor: this.chartColors[i],
        pointHoverBorderWidth: 0,
        pointHoverRadius: 4,
        pointRadius: 0
      };
    });

    if (this.chart && this.chart.canvas) {
      this.chart.data = chartData;
      this.chart.options.scales.x.time.unit = response.interval;
      this.chart.update();
      return;
    }

    this.chart = new Chart(this.chartTarget, {
      type: 'bar',
      data: chartData,
      options: {
        layout: {
          padding: 5
        },
        maintainAspectRatio: false,
        hover: {
          mode: 'index',
          intersect: false
        },
        animation: {
          duration: 300
        },
        scales: {
          x: {
            offset: true,
            stacked: true,
            distribution: 'series',
            type: 'time',
            time: {
              displayFormats: {
                month: 'MMM',
                quarter: 'Qq'
              },
              unit: response.interval,
              round: response.interval,
            },
            adapters: {
              date: {
                zone: this.timezoneOffset
              }
            },
            grid: {
              offset: false,
              drawOnChartArea: false,
              drawBorder: true
            },
            ticks: {
              color: colorFunc,
              font: {
                weight: weightFunc,
                size: sizeFunc
              },
              major: {
                enabled: true,
              },
              maxRotation: 0,
              maxTicksLimit: 12,
            },
            afterBuildTicks: afterBuildTicksFunc
          },
          y: {
            border: {
              display: false
            },
            stacked: true,
            grid: {
              display: false
            },
            position: 'right',
            ticks: {
              color: ChartStyles.axes.ticks.color,
              font: {
                size: ChartStyles.axes.ticks.size,
              },
              callback: (value) => numeral(value).format('$0,0[.]00a')
            }
          }
        },
        plugins: {
          annotation: {
            annotations: [
              {
                drawTime: 'beforeDatasetsDraw',
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y',
                value: -1,
                borderColor: ChartStyles.colors.blueGrayLight,
                borderWidth: 1,
                borderDash: [10]
              }
            ]
          },
          crosshair: false,
          legend: {
            display: false
          },
          tooltip: {
            axis: 'x',
            backgroundColor: ChartStyles.tooltips.backgroundColor,
            bodyColor: ChartStyles.tooltips.bodyFontColor,
            bodySpacing: ChartStyles.tooltips.bodySpacing,
            borderColor: 'rgba(0,0,0,0)',
            intersect: false,
            mode: 'index',
            position: 'nearest',
            titleColor: ChartStyles.tooltips.titleFontColor,
            titleMarginBottom: ChartStyles.tooltips.titleMarginBottom,
            padding: {
              x: ChartStyles.tooltips.padding,
              y: ChartStyles.tooltips.padding,
            },
            callbacks: {
              title: tooltipTitleCallback,
              label: ({ datasetIndex, raw }) => {
                const label = response.data[datasetIndex]?.name;
                return `${label}: ${numeral(raw.y).format('$0,0')}`;
              }
            }
          }
        }
      }
    });
  }

  getNumeralFormat(number) {
    return (number >= 1000000) ? '$0,0[.]0a' : '$0,0';
  }

  hideContent() {
    this.loadingCtrTarget.classList.add('d-none');
  }

  requestChartData(params = {}, hide = false) {
    if (hide) { this.hideContent(); }

    const queryString = new URLSearchParams(params).toString();
    const url = `${this.loadTrendPath}${this.data.get('id')}?${queryString}`;

    fetch(url, {
      method: 'GET',
      cache: 'default',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        this.buildChart(data);
        this.accountsTarget.innerHTML = data.accountsView;
        this.showContent();
      })
      .catch(error => console.error('There was been a problem with the fetch operation:', error));
  }

  showContent() {
    this.loadingCtrTarget.classList.remove('d-none');
  }

  toggleLabel({ target }) {
    let label;
    for (let i = 0; i < this.labelTargets.length; i++) {
      if (this.labelTargets[i].contains(target)) {
        label = this.labelTargets[i];
        break;
      }
    }

    if (!label) return;

    // Find the closest <li> element to determine the label's index
    let closestLi = label;
    while (closestLi && closestLi.nodeName !== 'LI') {
      closestLi = closestLi.parentNode;
    }

    // If closestLi wasn't found or isn't part of an <ul>/<ol>, exit
    if (!closestLi || !closestLi.parentNode || (closestLi.parentNode.nodeName !== 'UL' && closestLi.parentNode.nodeName !== 'OL')) return;

    const labelIndex = Array.prototype.indexOf.call(closestLi.parentNode.children, closestLi);
    const inactive = label.classList.contains('inactive');

    if (inactive) {
      label.classList.remove('inactive');
      this.chart.data.datasets[labelIndex].hidden = false;
    } else {
      label.classList.add('inactive');
      this.chart.data.datasets[labelIndex].hidden = true;
    }

    this.chart.update();
  }


  deleteTrend(_event) {
    this.element.remove();
  }

  toggleBreakdown({ target } = {}) {
    const closestLi = target.closest('li');

    let label;
    for (let i = 0; i < this.listTargets.length; i++) {
      if (closestLi.contains(this.listTargets[i])) {
        label = this.listTargets[i];
        break;
      }
    }

    if (!label) return; // No matching label found within closestLi

    if (target.textContent.includes('Collapse')) {
      target.innerHTML = "Expand<i class='fa-regular fa-plus ms-1'></i>";
    } else {
      target.innerHTML = "Collapse<i class='fa-regular fa-minus ms-1'></i>";
    }

    label.classList.toggle('collapse');
  }

  swapSortIcon(container, currentIconClass, newIconClass) {
    const icon = container.querySelector(`.${currentIconClass}`);

    if (!icon) {
      console.warn('FA Icon not found');
      return;
    }

    icon.classList.replace(currentIconClass, newIconClass);
  }


  toggleVisibility(event) {
    const el = this.element;
    const csrfToken = document.querySelector("meta[name='csrf-token']").getAttribute("content");

    // NOTE: We are checking the `checked` property from the event, NOT the dom attribute.
    const setHiddenParam = event.target.checked ? false : true;

    fetch(`${this.loadTrendPath}${this.data.get('id')}`, {
      method: 'PUT',
      headers: {
        "X-CSRF-Token": csrfToken,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({ trend: { hidden: setHiddenParam, index: 999 } })
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
      })
      .then(data => {
        /// State management hell below to show/hide things based on checkbox state.
        // Be really careful when changing code below.
        const item = this.element;
        const container = item.parentNode;

        if (event.target.checked) {
          this.requestChartData();
          this.accountsTarget.style.display = '';
          this.chartTypeTogglesTarget.style.display = '';
          this.swapSortIcon(item, 'fa-ban', 'fa-up-down');
          item.classList.add('drag-handle');
          item.querySelector(".card-body").classList.remove("collapse");
        } else {
          container.appendChild(item);
          item.querySelector('canvas').style.display = 'none';
          this.accountsTarget.style.display = 'none';
          this.chartTypeTogglesTarget.style.display = 'none';
          if(this.chart) {
            this.chart.destroy();
          }
          this.swapSortIcon(item, 'fa-up-down', 'fa-ban');
          item.classList.remove('drag-handle');
          item.querySelector(".card-body").classList.add("collapse");
        }
      });
  }
}
