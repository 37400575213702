import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  open(event) {
    if (event) { event.preventDefault() }
    var modal = new bootstrap.Modal("#partnersModal");
    modal.show()
    document.getElementById('partnersModal').getElementsByClassName('modal-content')[0].innerHTML = this.modalContent();
  }

  modalContent() {
    const partnerData = JSON.parse(this.element.dataset.partner)
    return `
      <div class="modal-header" style="max-height: 400px;">
        <div class="d-flex justify-content center">
          <img src="${partnerData.logo_url}" height="100"/>
        </div>
      </div>
      <div class="modal-body">
        ${partnerData.long_description}
        <a href="${partnerData.link}" target="_blank" class="btn btn-primary">${partnerData.button_text}<a>
      </div>
    `
  }
}
