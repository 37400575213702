import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["parentCheckbox", "childCheckbox", "nestedScopes", "allCheckbox", "otherCheckbox"]

  connect() {
    // Ensure the parent checkbox state is correct on page load
    this.updateParentCheckbox()

    this.element.addEventListener('bk360-permission-template-applied', this.handleScopeChange.bind(this))
  }

  disconnect() {
    this.element.removeEventListener('bk360-permission-template-applied', this.handleScopeChange.bind(this))
  }

  handleScopeChange(event) {
    // Only proceed if the 'allCheckbox' target exists
    if (this.hasAllCheckboxTarget) {
      const allCheckbox = this.allCheckboxTarget
      const otherCheckboxes = this.otherCheckboxTargets

      // If "All" is checked, disable and uncheck the other checkboxes
      if (event.target.dataset.scope === "all") {
        if (allCheckbox.checked) {
          otherCheckboxes.forEach(checkbox => {
            checkbox.checked = false
            checkbox.disabled = true
          })
        } else {
          // If "All" is unchecked, enable the other checkboxes
          otherCheckboxes.forEach(checkbox => {
            checkbox.disabled = false
          })
        }
      }
    }
  }

  // Update the parent checkbox when any child scope checkbox is clicked
  updateParentCheckbox() {
    const allChecked = this.childCheckboxTargets.every(checkbox => checkbox.checked)
    const someChecked = this.childCheckboxTargets.some(checkbox => checkbox.checked)

    // Handle the case where there's only one child option and it's unchecked
    if (this.childCheckboxTargets.length > 1) {
      // Update parent checkbox state based on child checkboxes
      if (allChecked && this.childCheckboxTargets.length > 0) {
        this.parentCheckboxTarget.checked = true
      } else if (someChecked) {
        this.parentCheckboxTarget.checked = false
      } else {
        // If no child checkboxes are selected, ensure the parent is unchecked
        this.parentCheckboxTarget.checked = false
      }
    }
  }
}
