import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static targets = ['offcanvas']

  connect() {
    this.offcanvas = new bootstrap.Offcanvas(this.offcanvasTarget, {backdrop: this.offcanvasTarget.dataset.offcanvasBackdrop, scroll: false})
    this.offcanvas.show()
  }

  open() {
    this.offcanvas.show()
  }

  close(event) {
    event.preventDefault();
    this.offcanvas.hide()
  }
}
