import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['metric', 'mainYear', 'pastYear'];

  connect() {
    // Store the original <option> elements for later use
    this.pastYearOptions = Array.from(this.pastYearTarget.querySelectorAll('option'));
    this.setPastYearOptions();
  }

  setPastYearOptions() {
    const originalValue = this.pastYearTarget.value;
    // Clear existing options
    this.pastYearTarget.innerHTML = '';

    this.pastYearOptions.forEach(option => {
      if (option.value < this.mainYearTarget.value) {
        // Append option if it meets the condition
        this.pastYearTarget.appendChild(option.cloneNode(true)); // Clone to preserve the original option for future use
      }
    });

    // Determine and set the new value
    const newValue = this.pastYearTarget.querySelector(`option[value="${originalValue}"]`) && originalValue < this.mainYearTarget.value ?
                     originalValue :
                     this.pastYearTarget.options[0]?.value;
    this.pastYearTarget.value = newValue || '';
  }

  changeYear(event) {
    // Directly manipulate the 'selected' property of the <option> elements
    Array.from(event.target.options).forEach(option => {
      option.selected = option.value === event.target.value;
    });

    this.setPastYearOptions();
    this.requestChartUpdate();
  }

  changeYearType(event) {
    const button = event.currentTarget;
    const newYearType = button.dataset.type;

    if (this.data.get('year-type') === newYearType) {
      return;
    }

    // Remove 'active' class from all buttons and add it to the clicked one
    button.closest('.btn-group').querySelectorAll('.toggle-button').forEach(btn => {
      btn.classList.remove('active');
    });
    button.classList.add('active');

    this.data.set('year-type', newYearType);
    this.requestChartUpdate();
  }

  requestChartUpdate() {
    this.metricTargets.forEach(metric => {
      metric.metric.requestChartData({
        my: this.mainYearTarget.value,
        py: this.pastYearTarget.value,
        yt: this.data.get('year-type')
      });
    });
  }
}
