import { Controller } from '@hotwired/stimulus'

// Override browser behavior that retains unsaved form selections on refresh.
// This ensures the form always resets between visits.
//
// This issue is not caused by Turbo but I'm using turbo listeners to help
// workaround it.
export default class extends Controller {
  connect() {
    this.turboLoadHandler = this.resetForm.bind(this);
    document.addEventListener("turbo:load", this.turboLoadHandler);
    document.addEventListener("turbo:before-cache", this.turboLoadHandler);
    this.resetForm();
  }

  resetForm() {
    this.element.reset();
  }

  disconnect() {
    document.removeEventListener("turbo:load", this.turboLoadHandler);
    document.removeEventListener("turbo:before-cache", this.turboLoadHandler);
  }
}

