import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect(event) {
    new bootstrap.Dropdown(this.element);
  }

  closeDropdown(event) {
    bootstrap.Dropdown.getInstance(this.element).hide();
  }

  toggleDropdown(event) {
    event.preventDefault();
    event.stopPropagation();
    bootstrap.Dropdown.getInstance(this.element).toggle();
  }
}
