import { Controller } from '@hotwired/stimulus';

export const LegalStructureConcern = {
  
  connect() {
    this.legalStructureChanged();
  },
  
  legalStructureChanged() {
    const legalStructureSelect = document.querySelector("#account_legal_structure");
    const taxFilingTypeSelect = document.querySelector("#account_tax_filing_type");

    legalStructureSelect.addEventListener('change', () => {
      const selectedLegalStructure = legalStructureSelect.value;
      const taxFilingTypeOptions = this.getTaxFilingTypeOptions(selectedLegalStructure);

      taxFilingTypeSelect.innerHTML = '';

      const blankOption = document.createElement('option');
      blankOption.value = '';
      blankOption.text = 'Select a tax filing type';
      taxFilingTypeSelect.appendChild(blankOption);

      taxFilingTypeOptions.forEach((option) => {
        const optionElement = document.createElement('option');
        optionElement.value = option;
        optionElement.text = option;
        taxFilingTypeSelect.appendChild(optionElement);
      });
    });
  },

  getTaxFilingTypeOptions(legalStructure) {
    const validTaxFilingOptions = {
      "LLC": ["C Corp - 1120", "S Corp - 1120s", "Partnership-1065", "Disregarded Entity - SEC C"],
      "LLP": ["Partnership-1065"],
      "Corporation": ["C Corp - 1120", "S Corp - 1120s"],
      "Non-Profit": ["Non-Profit - 990"],
      "Trust": ["Estates and Trust - 1041"],
      "Unincorporated/DBA": ["1040 Schedule C"]
    }
    return validTaxFilingOptions[legalStructure];
  }
}
