import { Controller } from '@hotwired/stimulus';
import Quill from 'quill';

export default class extends Controller {
  connect() {
    this.quill = new Quill(this.element, {
      theme: 'snow',
      modules: {
        toolbar: [
          [{ 'header': [1, 2, false] }],
          ['bold', 'italic', 'underline'],
          ['image', 'code-block']
        ]
      }
    });
  }
}
