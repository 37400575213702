import { Controller } from '@hotwired/stimulus'

export default class extends Controller {

  connect() {
    document.addEventListener('trix-initialize', this.handleTrixInitialize.bind(this), { once: true })
  }


  handleTrixInitialize() {
    if (document.querySelectorAll('trix-toolbar').length > 0) {
      document.querySelectorAll('trix-toolbar').forEach((toolbar) => {
        toolbar.style.display = 'none';
      })
    }
  }
}
