import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['select']

  updatePreference(event) {
    const selectedValue = event.target.value;
    const preferenceType = event.target.dataset.preferenceType;
    const preferenceKey = event.target.dataset.preferenceKey;

    this.savePreference(preferenceType, preferenceKey, selectedValue);
  }

  savePreference(preferenceType, preferenceKey, value) {
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

    fetch('/user_preferences', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken
      },
      body: JSON.stringify({
        user_preference: {
          preference_type: preferenceType,
          preference_key: preferenceKey,
          preference_value: { value: value }
        }
      })
    })
    .then(response => response.json())
    .then(data => {
      if (!data.success) {
        console.error('Failed to save preference:', data.errors);
      }
    })
    .catch(error => {
      console.error('Error saving preference:', error);
    });
  }
}
