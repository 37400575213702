import { Controller } from '@hotwired/stimulus'
import 'bootstrap-datepicker'
import $ from 'jquery'

export default class extends Controller {
  get options() {
    let data = $(this.element).data();
    let options = {
      autoclose: true,
      assumeNearbyYear: true
    };

    for (let item in data) {
      if (item.indexOf('datepicker') > -1) {
        options = { ...options, [this.parseOptions(item)]: data[item] };
      }
    }

    return options;
  }

  connect() {
    this.datepicker = $(this.element)
      .datepicker(this.options)

    if(this.options.openOnConnect) {
      $(this.element).datepicker('show')
    }
  }

  parseOptions = (item) => {
    item = item.slice('datepicker'.length);
    return item.charAt(0).toLowerCase() + item.slice(1);
  }; 
}
