import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['codeInput', 'form']

  connect() {
    this.codeInputTargets[0].focus();
  }

  autoSubmit() {
    const emptyInputs = this.codeInputTargets.some(el => el.value === '');
    if (emptyInputs) { return; }
    this.formTarget.requestSubmit()
  }

  focusProperInput(event) {
    const firstInput = this.codeInputTargets[0];
    if (event.target === firstInput) return;

    if (firstInput.value === '') {
      firstInput.focus();
    } else {
      const previousElement = event.target.previousElementSibling;
      if (previousElement && previousElement.value === '') {
        previousElement.focus();
      }
    }
  }

  handleBackspace(event) {
    if (event.keyCode !== 8 && event.keyCode !== 37) return;

    const prevInput = this.codeInputTargets[Math.max(0, this.codeInputTargets.indexOf(event.target) - 1)]
    if (event.target.value.length === 0) prevInput.focus();
  }

  handleInput(event) {
    const input = event.target;
    if (event.keyCode === 13 || event.keyCode === 9) return;

    if (event.keyCode >= 48 && event.keyCode <= 57 || event.keyCode === 8) {
      const nextInput = this.codeInputTargets[this.codeInputTargets.indexOf(input) + 1];

      if (nextInput) {
        setTimeout(() => nextInput.focus(), 10);
      } else {
        input.value = String.fromCharCode(event.keyCode);
        input.blur();
        this.autoSubmit();
      }
    } else {
      event.preventDefault();
    }
  }

  handleAutofill(event) {
    event.preventDefault();
    const code = event.target.value;
    if (code.length !== 6) return;
    this.codeInputTargets.forEach((input, index) => (input.value = code[index]));
    this.autoSubmit();
  }

  handlePaste(event) {
    event.preventDefault();
    const pastedText = event.clipboardData.getData('text');
    const code = pastedText.match(/\d+/g);
    if (!code) return;
    const stringCode = code.toString();
    const codeArray = stringCode.split('').map(digit => parseInt(digit, 10) || +digit)
    this.codeInputTargets.forEach((input, index) => (input.value = codeArray[index]));
    this.autoSubmit();
  }
}
