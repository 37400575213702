import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
    static targets = ['icon']

    connect() {

        console.debug("Full Screen Controller Connected")
        // this.initTopbarMenu()
    }

    toggle(e) {
        e.preventDefault();
        document.body.classList.toggle('fullscreen-enable')
        if (!document.fullscreenElement && /* alternative standard method */ !document.mozFullScreenElement && !document.webkitFullscreenElement) {  // current working methods
            this.iconTarget.classList.add( 'fa-regular');
            this.iconTarget.classList.toggle('fa-compress', 'fa-expand');
            if (document.documentElement.requestFullscreen) {
                document.documentElement.requestFullscreen();
            } else if (document.documentElement.mozRequestFullScreen) {
                document.documentElement.mozRequestFullScreen();
            } else if (document.documentElement.webkitRequestFullscreen) {
                document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
            }
        } else {
            this.iconTarget.classList.add( 'fa-regular');
            this.iconTarget.classList.toggle( 'fa-expand', 'fa-compress');
            if (document.cancelFullScreen) {
                document.cancelFullScreen();
            } else if (document.mozCancelFullScreen) {
                document.mozCancelFullScreen();
            } else if (document.webkitCancelFullScreen) {
                document.webkitCancelFullScreen();
            }
        }
    }

}
