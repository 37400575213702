import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['folderModal'];

  connect() {
    this.element[this.identifier] = this;
  }

  open(event) {
    if (event) { event.preventDefault() }
    new bootstrap.Modal("#foldersModal").show()
    document.documentElement.classList.add('scroll-lock');
  }

  close(event) {
    if (event) { event.preventDefault(); }
    hideModal()
    document.documentElement.classList.remove('scroll-lock');
  }

  overlayClick(event) {
    if (this.data.get('closable') === 'true') {  hideModal() }
  }

  hideModal() {
    const foldersModal = document.getElementById('foldersModal');
    const modal = bootstrap.Modal.getInstance(foldersModal);
    modal.hide()
  }

  escClose(event) {
    if (event.keyCode !== 27) { return; }
    if (this.data.get('closable') === 'true') { this.close(); }
  }
}
