import $ from 'jquery'
import 'regenerator-runtime/runtime';
import "@hotwired/turbo-rails"
import 'trix';
import 'daterangepicker'

import 'tippy.js/dist/tippy.css';
import 'tippy.js/animations/shift-toward.css';
import 'tippy.js/themes/light-border.css';
import '@rails/actiontext';
import '@fortawesome/fontawesome-svg-core/styles.css';
import select2 from 'select2';
select2(); // necessary to init select2

import 'jquery-mask-plugin'
import apexcharts from 'apexcharts';
import * as bootstrap from 'bootstrap';
import Cleave from 'cleave.js';
import moment from 'moment';
import numeral from 'numeral'
import tippy from 'tippy.js';
import { delegate } from 'tippy.js';

// Rails imports
import Rails from '@rails/ujs';
import * as ActiveStorage from "@rails/activestorage"
import { Application } from '@hotwired/stimulus';
import { registerControllers } from 'stimulus-vite-helpers'
import Appsignal from "@appsignal/javascript"
import { plugin as consolePlugin } from "@appsignal/plugin-breadcrumbs-console"
import { plugin as networkPlugin } from "@appsignal/plugin-breadcrumbs-network"
import { plugin as pathPlugin } from "@appsignal/plugin-path-decorator"
import { installErrorHandler } from "@appsignal/stimulus";
import { config, library, dom } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'

library.add(fas, far, fad)
config.mutateApproach = 'sync'

dom.watch()

// local imports
import Utilities from '@/utils/utilities'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the vite_image_tag helper in views (e.g <%= vite_image_tag 'rails.png' %>)
// or the imagePath JavaScript helper below.
const images = import.meta.glob('./**/images');

// Setup global windows
window.jQuery = window.$ = $
window.Controllers = {};
window.App = {}
window.App.Utilities = Utilities
window.ApexCharts = apexcharts;
window.bootstrap = bootstrap;
window.numeral = numeral;

// Rails-related
if (!window.Rails) { Rails.start() } // hack for now until we move off of UJS in favor of Turbo
ActiveStorage.start()


// AppSignal error tracking / breadcrumbs

// These keys are okay to expose to public according to AppSignal

const appsignal = process.env.productionMode === 'true'
                ? new Appsignal({ key: "cb9c2ecb-b9d8-42ae-817b-a2bbf6c94f85" })
                : new Appsignal({ key: "5e6a4845-f426-4374-9bf6-3baee51c00ad" })
appsignal.use(consolePlugin())
appsignal.use(networkPlugin())
appsignal.use(pathPlugin())

const application = Application.start()
installErrorHandler(appsignal, application) // line needs to be before registerControllers

// Import Stimulus controllers globally
const controllers = import.meta.glob('@/**/*_controller.{coffee,js}', { eager: true })
registerControllers(application, controllers)

// DebugMode - why we need this?
App.DebugMode = process.env.developmentMode === 'true';

// Disable console warnings unless development
if (!App.DebugMode) {
  console.warn = function() {};
}

App.log = function(message) {
  if (App.DebugMode) {
    console.log("BK360: " + message);
  }
};


numeral.register('locale', 'us', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'K',
    million: 'M',
    billion: 'B',
    trillion: 'T'
  },
  currency: { symbol: '$' }
});

numeral.locale('us');

// TODO: Remove these global events :[
$(document).on('turbo:load', function() {
  setTimeout(function() {
    $('.alert-box.success').fadeOut();
  }, 2000);

  // set app variables from body data
  $.each($('body').data(), function(k, v) {
    App[k] = v;
    if (App.DebugMode) {
      App.log("Setting - " + k + ": " + v);
    }
  });

  setTimeout(function() {
    $('body').removeClass('preload');
    $('html, body').removeClass('no-scroll');
  }, 100);

  $(window).on('resize', function() {
    $('body').addClass('preload');
    clearTimeout(window.resizeTimer);
    window.resizeTimer = setTimeout(function() {
      $('body').removeClass('preload');
    }, 500);
  });

  if (App.currentUser && App.currentAccount) {
    // if (typeof(Intercom) == 'undefined' || App.intercomData == 'undefined') {
    //   Intercom('boot', App.intercomData);
    // }
  }

  $('[data-numeral]').each(function() {
    var value = $(this).html();
    $(this).html(numeral(value).format($(this).data('numeral')));
    if ($(this).data('tooltip') != undefined) {
      $(this).attr('data-tippy-content', numeral(value).format('$0,0'));
      $(this).attr('data-tippy-placement', 'top');
    }
  });
});

window.addEventListener('turbo:load', () => {
  // $('[data-toggle="date-picker"]').datepicker({});
  $('[data-toggle="date-picker-range"]').daterangepicker({
    ranges: {
      'Today': [moment(), moment()],
      'Yesterday': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Last 7 Days': [moment().subtract(6, 'days'), moment()],
      'This Month': [moment().startOf('month'), moment().endOf('month')],
      'Last Month': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')],
      'This Quarter': [moment().quarter(moment().quarter()).startOf('quarter'), moment().quarter(moment().quarter()).endOf('quarter')],
      'Last Quarter': [moment().subtract(1, 'quarter').startOf('quarter'), moment().subtract(1, 'quarter').endOf('quarter')],
      'This Year': [moment().startOf('year'), moment().endOf('year')],
      'Last Year': [moment().subtract(1, 'year').startOf('year'), moment().subtract(1, 'year').endOf('year')],
      'This YTD': [moment().startOf('year'), moment()],
    },
    alwaysShowCalendars: true,
    opens: 'right'
  })
});

// Original from the transfer_to_vite folder

import Chart from 'chart.js/auto';
import ChartStyles from '@/utils/chart_styles';
import AnnotationPlugin from 'chartjs-plugin-annotation';
// This chart doesn't currently support Chart.js v4, so we've brough the code
// in to this repo and are importing it directly. Once, it has been updated,
// we can remove this and import it from npm.
// See: https://github.com/ciprianciurea/chartjs-plugin-doughnutlabel/issues
import DoughnutLablesPlugin from '@/utils/doughnut_label_plugin';
// Specifically import the esm version of the plugin, since we're pointing to
// the v4.0.1 branch which is not published to npm yet and there is no dist/
// folder in the repo.
// See: https://github.com/AbelHeinsbroek/chartjs-plugin-crosshair/issues/95
import CrosshairPlugin from 'chartjs-plugin-crosshair/src/index.esm';

Chart.defaults.font.family = 'Montserrat';
Chart.defaults.color = ChartStyles.colors.blueGray;
Chart.defaults.font.weight = '600'

Chart.register(AnnotationPlugin);
Chart.register(CrosshairPlugin);
Chart.register(DoughnutLablesPlugin);

Turbo.StreamActions.redirect = function() {
  Turbo.visit(this.target);
};

Turbo.StreamActions.close_modal = function() {
  const element = document.getElementById('updated-modal')
  const modal = bootstrap.Modal.getInstance(element)
  modal.hide()
}

Turbo.StreamActions.close_offcanvas = function() {
  const element = document.getElementById('updated-offcanvas')
  const offcanvas = bootstrap.Offcanvas.getInstance(element)
  offcanvas.hide()
}

