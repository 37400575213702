// Used when editing an inline edit on a form input
//
// Example: Checklist Items
// When you click a checklist, it replaces the turbo_frame with the checklist form
// to allow users to edit without going into the form.
//
// This controller will activate once they're on the form,
// and sets the input and places it at the end. It also auto submits if
// a user hit enters.
// Lastly, if they hit esc instead of enter, we roll the change back and auto-submit the form
// to reset the turbo frame (not sure if I like this but it gets the job done for now)
import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "input", 'cancel']

  connect() {
    this.originalText = this.inputTarget.value
    this.focusInput()
    this.cursorToEnd()
  }

  focusInput() {
    this.inputTarget.focus()
  }

  cursorToEnd() {
    this.inputTarget.selectionStart =
      this.inputTarget.selectionEnd =
        this.inputTarget.value.length
  }

  submitForm(event) {
    // prevents enter from submitting the form when @mentions are happening
    if (document.querySelector('.tribute-container') !== null && document.querySelector('.tribute-container:not([style*="display: none;"])')) { return; }

    // prevents double submissions
    if (this.inputTarget.disabled) return

    if(this.inputTarget.value.length > 0 || event.key === 'Escape') {
      this.element.requestSubmit()
      this.inputTarget.disabled = true
    }
  }

  abort(event) {
    this.cancelTarget.value = true
    this.inputTarget.value = this.originalText
    this.submitForm(event) // let's auto submit the original text so it redirects back to our show
    event.stopPropagation() // prevents our task modal from closing when we hit esc
  }
}
