import 'chartjs-adapter-luxon';
import { DateTime } from 'luxon';
import Chart from 'chart.js/auto';
import ChartStyles from '@/utils/chart_styles';
import numeral from 'numeral';
import { Controller } from '@hotwired/stimulus';
import {
  afterBuildTicksFunc,
  colorFunc,
  getTimezoneOffset,
  sizeFunc,
  tooltipTitleCallback,
  weightFunc
} from '@/utils/chart_helpers';

export default class extends Controller {
  static targets = ['chart'];

  connect() {
    this.timezoneOffset = getTimezoneOffset();
    this.buildChart();
  }

  disconnect() {
    this.chart.destroy();
  }

  changeTimeFrame(event) {
    const { value } = event.target;
    const url = new URL(window.location);
    url.searchParams.set('tf', value);
    if ((url.searchParams.get('i') === 'year') && !['last_2_years', 'last_3_years'].includes(value)) {
      url.searchParams.delete('i');
    }

    Turbo.visit(url);
  }

  changeInterval(event) {
    const url = new URL(window.location);
    url.searchParams.set('i', event.target.value);
    Turbo.visit(url);
  }

  buildChart() {
    const ctx = $(this.chartTarget);
    const interval = ctx.data('interval');

    const plotData = {
      datasets: [
        {
          label: 'Net',
          borderColor: ChartStyles.colors.darkBlue,
          fill: 0,
          lineTension: ChartStyles.tension,
          pointBackgroundColor: ChartStyles.colors.darkBlue,
          pointBorderWidth: 0,
          pointHitRadius: 20,
          pointHoverBackgroundColor: ChartStyles.colors.darkBlue,
          pointHoverBorderWidth: 0,
          pointHoverRadius: 8,
          pointRadius: 4,
          type: 'line',
          data: ctx.data('net').map(point => ({
            x: DateTime.fromISO(point.x).setZone(this.timezoneOffset),
            y: point.y
          }))
        },
        {
          label: 'Cash In',
          backgroundColor: ChartStyles.colors.green,
          data: ctx.data('cashIn').map(point => ({
            x: new Date(point.x),
            y: point.y
          }))
        },
        {
          label: 'Cash Out',
          backgroundColor: ChartStyles.colors.red,
          data: ctx.data('cashOut').map(point => ({
            x: new Date(point.x),
            y: point.y
          }))
        }
      ]
    };

    this.chart = new Chart(ctx, {
      type: 'bar',
      data: plotData,
      options: {
        layout: {
          padding: {
            left: 10
          }
        },
        maintainAspectRatio: false,
        scales: {
          x: {
            distribution: 'series',
            stacked: true,
            type: 'time',
            time: {
              displayFormats: {
                month: 'MMM',
                quarter: 'Qq'
              },
              unit: interval,
              round: interval
            },
            grid: {
              offset: false,
              drawOnChartArea: false,
              drawBorder: true
            },
            ticks: {
              color: colorFunc,
              major: {
                enabled: true
              },
              font: {
                weight: weightFunc,
                size: sizeFunc
              },
              maxRotation: 0,
            },
            afterBuildTicks: afterBuildTicksFunc
          },
          y: {
            border: {
              display: false
            },
            stacked: true,
            grid: {
              display: false
            },
            ticks: {
              color: ChartStyles.axes.ticks.color,
              font: {
                size: ChartStyles.axes.ticks.size
              },
              callback(value) {
                return numeral(Math.abs(value)).format('$0,0[.]00a');
              }
            }
          }
        },

        plugins: {
          crosshair: false,
          annotation: {
            annotations: [
              {
                drawTime: 'beforeDatasetsDraw',
                type: 'line',
                mode: 'horizontal',
                scaleID: 'y',
                value: 0,
                borderColor: ChartStyles.colors.blueGrayLight,
                borderWidth: 1,
                borderDash: [10]
              }
            ]
          },
          legend: {
            labels: {
              boxWidth: 10,
              padding: 20,
              usePointStyle: true
            }
          },
          tooltip: {
            axis: 'x',
            backgroundColor: ChartStyles.tooltips.backgroundColor,
            bodyColor: ChartStyles.tooltips.bodyFontColor,
            bodySpacing: ChartStyles.tooltips.bodySpacing,
            mode: 'index',
            position: 'nearest',
            titleColor: ChartStyles.tooltips.titleFontColor,
            titleMarginBottom: ChartStyles.tooltips.titleMarginBottom,
            padding: {
              x: ChartStyles.tooltips.padding,
              y: ChartStyles.tooltips.padding,
            },
            callbacks: {
              title: tooltipTitleCallback,
              label: ({ dataset, datasetIndex, raw: { y } }) => {
                let value = (datasetIndex === 2) ? Math.abs(y) : y;
                return `${dataset.label}: ${numeral(value).format('$0,0')}`;
              }
            }
          }
        }
      }
    })
  }
};
