import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkbox', 'popoverBody'];

  open(e) {
    e.preventDefault()
    // popoverBodyTargets not available due to wrapper, specifying stimulus controller, is within a loop
    let openPopovers = $('.popover-body').not('.hidden')

    for (let i = 0; i < openPopovers.length; i++) {
      openPopovers[i].classList.add('hidden')
    }
    this.popoverBodyTarget.classList.remove('hidden')
  }

  select(e) {
    this.checkboxTarget.classList = e.target.classList
    this.close()
  }

  close() {
    this.popoverBodyTarget.classList.add('hidden')
  }
}
