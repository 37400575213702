import { Controller } from '@hotwired/stimulus';

/*
 * Reformats currency numbers to reduce the amount of screen space consumed.
 * Particularly useful for tables.
 *
 * $142       -> $142
 * $5,000     -> $5K
 * $5,250     -> $5.3K
 * $1,000,400 -> $1M
 * $1,300,400 -> $1.3M
 *
 * Initial use-case is for Expense Insights. A similar feature exists on
 * the /overview page but it is server-side rendered and it's kinda gross.
 * Ideally we fix the overview page to use this as well.
 *
 * Configuration options:
 * - selector: which nodes to format. Only targets text nodes within the selector.
 * - hideParent: does not display the parent element of `selector` while formatting.
 *               You probably want to leave this as the default (enabled).
*/
export default class extends Controller {
  static values = {
      selector: { type: String, default: 'td' },
    hideParent: { type: Boolean, default: true },
  }

  connect() {
    if(this.hideParentValue) {
       this.element.style.opacity = 'hidden'; // Hide the container for FOUC reasons
    }

    requestAnimationFrame(() => {
      this.formatCurrencyElements();
      this.element.style.visibility = 'visible';
    });
  }

  formatCurrencyElements() {
    const elements = this.element.querySelectorAll(this.selectorValue);
    elements.forEach(element => {
      this.formatTextNodes(element);
    });
  }

  formatTextNodes(node) {
    node.childNodes.forEach(child => {
      if (child.nodeType === Node.TEXT_NODE) {
        const text = child.textContent.trim();
        const match = text.match(/^\$([\d,]+(?:\.\d+)?)/);
        if (match) {
          const number = parseFloat(match[1].replace(/,/g, ''));
	  const wrapper = document.createElement('span');
	  wrapper.innerHTML = this.formatCurrency(number);
	  child.replaceWith(wrapper);
	}
      } else if (child.nodeType === Node.ELEMENT_NODE) {
        this.formatTextNodes(child); // Recursively handle nested elements
      }
    });
  }

  formatCurrency(amount) {
    if (amount < 1000) {
      return `$${amount.toFixed(0)}`;
    } else if (amount < 1_000_000) {
      const thousands = (amount / 1_000).toFixed(1);
      return `$${thousands.replace(/\.0$/, '')}<em>K</em>`;
    } else if (amount < 1_000_000_000) {
      const millions = (amount / 1_000_000).toFixed(1);
      return `$${millions.replace(/\.0$/, '')}<em>M</em>`;
    } else if (amount < 1_000_000_000_000) {
      const billions = (amount / 1_000_000_000).toFixed(1);
      return `$${billions.replace(/\.0$/, '')}<em>B</em>`;
    } else {
      // Pray we get a customer that hits this line of code.
      const trillions = (amount / 1_000_000_000_000).toFixed(1);
      return `$${trillions.replace(/\.0$/, '')}<em>T</em>`;
    }
  }
}
