import { Controller } from '@hotwired/stimulus';
import Tooltip from 'bootstrap/js/dist/tooltip';

export default class extends Controller {
  static values = { originalContent: String, title: String }

  connect() {
    const content = this.data.get("originalContent");
    const title = this.data.get("title");

    if (content && content.length > 70) {
      // Replace multiple newlines with a single paragraph break
      const formattedContent = content.replace(/(\r?\n)+/g, '<br /><br />');
      const wrappedContent = `<h5>${title}</h5>${formattedContent}`;

      this.element.setAttribute('title', wrappedContent);
      this.element.classList.add('text-truncate');

      this.tooltip = new Tooltip(this.element, {
        placement: 'top',
        trigger: 'hover',
        html: true,
        title: wrappedContent,
      });
    }
  }

  hideTooltip() {
    if (this.tooltip) {
      this.tooltip.hide();
    }
  }

  disconnect() {
    if (this.tooltip) {
      this.tooltip.dispose();
    }
  }
}

