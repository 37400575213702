import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = { prompt: String, confirmText: String };

  confirm(event) {
    const confirmPrompt = this.promptValue || "Type CONFIRM to proceed:";
    const confirmText = this.confirmTextValue || "CONFIRM";

    const userInput = prompt(confirmPrompt);

    if (userInput === null || userInput !== confirmText) {
      // If the user clicks "Cancel" or enters the wrong text, prevent the action
      event.preventDefault();
      event.stopImmediatePropagation();

      alert("Invalid confirmation. Action canceled.");
    }
  }
}

