import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['item'];

  connect() { }

  download(event) {
    event.preventDefault()
    event.stopPropagation();
    window.location.href = event.target.dataset.downloadUrl
  }

  copyUrl(event) {
    event.preventDefault()
    event.stopPropagation();
    navigator.clipboard.writeText(event.target.dataset.copyUrl)
    this.createToast({ color: 'success', message: 'URL was copied' });
  }

  delete(event) {
    event.preventDefault()
    event.stopPropagation();
    if (event.target.dataset.restrictModification === 'true') {
      alert("You cannot delete this folder since it was created automatically by Bookkeeper360.");
      return;
    }
    $.ajax({
      url: `${window.location.origin}${event.target.dataset.deleteUrl}`,
      type: 'DELETE'
    }).done(({ color, message }) => {
      this.createToast({ color, message });
      document.querySelector(`[data-item-id="${event.target.dataset.id}"]`).style.display = 'none';
    })
  }

  restore(event) {
    event.preventDefault();
    event.stopPropagation();

    $.ajax({
      url: `${window.location.origin}${event.target.dataset.restoreUrl}`,
      type: 'PATCH'
    }).done(({ color, message }) => {
      this.createToast({ color, message });
      document.querySelector(`[data-item-id="${event.target.dataset.id}"]`).style.display = 'none';
    })
  }

  createToast({ color, message }) {
    const toastContainer = document.getElementsByClassName('toast-container')[0]
    toastContainer.innerHTML += this.buildToast(color, message)
  }

  buildToast(color, message) {
    return `
      <div class="toast show align-items-center text-white bg-${color} border-0 mb-4 me-4" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
          <div class="toast-body">
            ${message}
          </div>
          <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
      </div>
    `
  }
}
