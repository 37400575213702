import { Controller } from '@hotwired/stimulus'
import Cleave from 'cleave.js'
export default class extends Controller {
  static targets = ['ein']

  connect() {
    if (this.hasEinTarget) {
      this.einTargets.forEach(function(target) {
        new Cleave(target, {
          numericOnly: true,
          delimiter: '-',
          blocks: [2, 7]
        });
      });
    }
  }
}
