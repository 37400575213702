import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["spinner"]

  connect() {
    this.element.addEventListener("click", this.showSpinner.bind(this));
  }

  showSpinner() {
    this.spinnerTarget.style.display = "inline-block";
  }
}
