import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['showMore', 'showLess']

  connect() {
    // defaults to showing less
    if (this.showLessTargets && this.showLessTargets.length) {
      this.showLessTargets.forEach((e) => {
        e.style='display: none;'
        e.classList.remove('flex')
      })
    }
  }

  showMore(e) {
    this.showMoreTargets[0].classList.remove('flex')
    this.showMoreTargets[0].style = 'display: none;'
    this.showLessTargets[0].style = 'display: flex;'
  }

  showLess() {
    this.showLessTargets[0].classList.remove('flex')
    this.showLessTargets[0].style = 'display: none;'
    this.showMoreTargets[0].style = 'display: flex;'
  }
}
