import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  disconnect() {
    this.element.removeEventListener('change');
  }

  connect() {
    this.element.addEventListener('change', event => (
      event.target.closest('form').requestSubmit()
    ));
  }
}