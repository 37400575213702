import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {

  connect() {
    this.timeout = null;
  }

  handleChange(event) {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.dispatch("timeout", { detail: { event: event } })
    }, 500);
  }
}
