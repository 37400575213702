import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ["templateSelect", "checkbox", "flashArea"]

  connect() {
    console.log('template-permissions connected', this.templateSelectTarget)
    this.templateSelectTarget.value = '';
    document.addEventListener('bk360-permissions-updated', this.clearFlashMessage.bind(this))
  }

  disconnect() {
    document.removeEventListener('bk360-permissions-updated', this.clearFlashMessage.bind(this))
  }

  clearFlashMessage() {
    if (this.hasFlashAreaTarget) {
      this.flashAreaTarget.innerHTML = ''
    }
  }

  showFlashMessage() {
    const flashMessage = this.templateSelectTarget.dataset.flashMessage || "Form updated with template permissions, but changes are not yet saved."

    if (this.hasFlashAreaTarget) {
      this.flashAreaTarget.innerHTML = `
          <div class="alert alert-info mt-2" role="alert">
            <i class="fa-solid fa-triangle-exclamation"></i>
            ${flashMessage}
          </div>
        `
    }
  }

  loadTemplatePermissions(event) {
    const confirmationMessage = this.templateSelectTarget.dataset.confirmationMessage || "Are you sure you want to load this template?"
    if (!confirm(confirmationMessage)) {
      this.templateSelectTarget.value = '';
      return
    }
    const templateId = this.templateSelectTarget.value

    if (templateId) {
      fetch(`/admin/permissions/employee_templates/${templateId}`, {
        headers: { "Accept": "application/json" }
      })
      .then(response => response.json())
      .then(data => {
        this.applyTemplatePermissions(data.permissions)
        this.showFlashMessage()
      })
      .catch(error => console.error("Error loading template permissions:", error))
    }
  }

  applyTemplatePermissions(permissions) {
    this.checkboxTargets.forEach(checkbox => {
      checkbox.checked = false
      checkbox.disabled = false
    })

    permissions.forEach(permission => {
      const permissionId = permission.employee_permission_id
      const scopes = permission.selectable_scopes

      scopes.forEach(scope => {
        const checkbox = this.checkboxTargets.find(checkbox => checkbox.dataset.permissionId === permissionId && checkbox.dataset.scope === scope)
        if (checkbox) {
          checkbox.checked = true

          // Signal the related checkbox controller we manipulated a checkbox
          // so it needs to re-run its behavior as if the user made the selection.
          let event = new Event('bk360-permission-template-applied', { bubbles: true });
          checkbox.dispatchEvent(event)
        }
      })
    })
  }
}

