import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  connect() {
    document.addEventListener('turbo:visit', this.showSpinner.bind(this));
    document.addEventListener('turbo:load', this.hideSpinner.bind(this));
  }

  disconnect() {
    document.removeEventListener('turbo:visit', this.showSpinner.bind(this));
    document.removeEventListener('turbo:load', this.hideSpinner.bind(this));
  }

  showSpinner() {
    if (!this.spinnerElement) {
      this.spinnerElement = document.createElement('i');
      this.spinnerElement.className = 'fas fa-rotate-right fa-spin';
      this.spinnerElement.style.fontSize = '1.5rem';
      this.spinnerElement.style.display = 'inline-block';
      this.element.appendChild(this.spinnerElement);
    } else {
      this.spinnerElement.style.display = 'inline-block';
    }
  }

  hideSpinner() {
    if (this.spinnerElement) {
      this.spinnerElement.style.display = 'none';
    }
  }
}
