import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";

export default class extends Controller {
  connect() {
    this.initializeDropzone();
    this.files = [];
    this.setupFormSubmission();
  }

  initializeDropzone() {
    const dropzoneConfig = {
      url: this.data.get("url"),
      paramName: this.data.get("paramName"),
      maxFiles: this.data.get("maxFiles"),
      maxFilesize: this.data.get("maxFilesize"),
      acceptedFiles: this.data.get("acceptedFiles"),
      addRemoveLinks: false,
      autoProcessQueue: false,
      previewTemplate: `
        <div class="dz-preview dz-file-preview card mt-3 mb-3 p-3">
          <div class="card-body">
            <div class="dz-details d-flex align-items-center">
              <img data-dz-thumbnail class="img-thumbnail border mr-3" />
              <div>
                <div class="dz-filename p-2"><span data-dz-name></span></div>
              </div>
            </div>
            <div class="p-1">
              <a href="#" class="p-1" data-dz-remove>
                <i class="fas fa-duotone fa-lg fa-trash"></i> Remove File
              </a>
            </div>
            <div class="dz-error-message text-danger mt-2"><span data-dz-errormessage></span></div>
          </div>
        </div>
      `,
    };

    this.dropzone = new Dropzone(this.element, dropzoneConfig);

    this.dropzone.on("addedfile", (file) => {
      this.files.push(file);
    });

    this.dropzone.on("removedfile", (file) => {
      this.files = this.files.filter(f => f !== file);
    });
  }

  setupFormSubmission() {
    const form = this.element.closest('form');
    form.addEventListener('submit', event => {
      event.preventDefault();
      const formData = new FormData(form);

      this.files.forEach((file) => {
        formData.append('file[]', file, file.name);
      });

      this.submitForm(formData);
    });
  }

  async submitForm(formData) {
    try {
      const response = await fetch(this.data.get("url"), {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        // Handle success
        this.handleSuccess(await response.json());
      } else {
        // Handle server-side failure
        this.handleFailure(await response.json());
      }
    } catch (error) {
      // Handle network failure or other errors
      this.handleFailure(error);
    }
  }

  handleSuccess(responseData) {
    window.location.reload();
  }

  handleFailure(error) {
    alert("Something went wrong uploading your files! " + error)
    window.location.reload();
  }
}
