import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['checkmark', 'removeBtn'];

  accountFormChanged(event) {
    event.target.form.requestSubmit();
  }

  accountUpdated(event) {
    clearTimeout(this.accountChangeTimeout);

    this.accountChangeTimeout = setTimeout(() => {
      checkmark.setAttribute('data-hidden', '');
      removeBtn.removeAttribute('data-hidden');
    }, 2000);
  }

  accountRemoved(event) {
    let tr = event.target.closest('tr');
    tr.style.transition = 'opacity 0.5s ease';
    tr.style.opacity = '0';
    setTimeout(() => {
      tr.remove();
    }, 500);
  }
}
