import { MOBILE_DEVICE_THRESHOLD } from '@/constants';
import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['icon']

    initialize() {
        this.config = {
            sidenav: {
                size: 'default'
            }
        }
        this.html = document.getElementsByTagName('html')[0];
        this.handleMobileLinkDismiss()
    }

    handleMobileLinkDismiss() {
        if(window.innerWidth > MOBILE_DEVICE_THRESHOLD) return

        const dismissSideBar = () => {
            this.html.classList.remove('sidebar-enable');
            document.body.removeChild(backdrop);
            document.body.style.overflow = null;
        }

        const sideNavItems = document.querySelectorAll('.side-nav-item');

        // Iterate over each 'side-nav-item' element
        sideNavItems.forEach(sideNavItem => {
            let innerSelf = sideNavItem;
            
            // Check if the current element has descendants with the same class
            if (innerSelf.querySelectorAll('.side-nav-item').length === 0) {
                innerSelf.addEventListener('click', dismissSideBar);
            }
        });
    }

    connect() {
        console.debug("Menu Toggle Controller Connected")
    }

    toggle(e) {
        var configSize = this.config.sidenav.size;
        var size = this.html.getAttribute('data-sidenav-size', configSize);

        if(window.innerWidth < MOBILE_DEVICE_THRESHOLD) {
            this.changeLeftbarSize('full', false);
            this.showBackdrop();
            this.html.classList.toggle('sidebar-enable');
            return
        }

        if (size !== 'full') {
            if (size === 'condensed') {
                this.changeLeftbarSize(configSize == 'condensed' ? 'default' : configSize, false);
                this.iconTarget.classList.add('fa-regular');
                this.iconTarget.classList.toggle('fa-ellipsis-vertical', 'fa-bars');
            } else {
                this.changeLeftbarSize('condensed', false);
                this.iconTarget.classList.add('fa-regular');
                this.iconTarget.classList.toggle( 'fa-bars', 'fa-ellipsis-vertical');
            }
        } else {
            this.showBackdrop();
        }

        // Todo: old implementation
        this.html.classList.toggle('sidebar-enable');

    }

    changeLeftbarSize(size, save) {
        this.html.setAttribute('data-sidenav-size', size);
        if (save) {
            this.config.sidenav.size = size;
        }
    }

    showBackdrop() {
        const backdrop = document.createElement('div');
        backdrop.classList = 'offcanvas-backdrop fade show';
        document.body.appendChild(backdrop);
        document.body.style.overflow = "hidden";
        const self = this
        backdrop.addEventListener('click', function (e) {
            self.html.classList.remove('sidebar-enable');
            document.body.removeChild(backdrop);
            document.body.style.overflow = null;
        })
        }
    }

