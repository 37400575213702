// Turbo Sort
//
// Used primary for sorting tables but can work with sorting other data
// as long as the backend controller accepts the sorting params
//
// Add a data-sort-target="sort", a data-action="click->turbo-sort#sort,
// and a data-sort-name="name" to a link or a button,
// and it will populate the URL params with the provided data-sort-name
// and auto-populate the direction of the sort

import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ['sort']

  connect() {
    this.params = new URLSearchParams(window.location.search)
  }

  sort(event) {
    event.preventDefault();
    this.setDirection(event.target.dataset.sortName)
    this.params.set('sort', event.target.dataset.sortName)
    Turbo.visit(window.location.pathname + '?' + this.params.toString(), { action: 'advance' })
  }

  setDirection(selectedSortName) {
    const direction = this.params.get('direction')
    // this selectedSortName check is necessary when selecting another column
    // when another column is already sorted.
    // Without it, selecting a column with another column desc means the user
    // will have to click the sort link twice.
    // We compare the selected sort name with the param sort name, and if they are
    // diff, reset it back to ascending
    if(selectedSortName !== this.params.get('sort')) {return this.params.set('direction', 'asc') };
    if (direction === null) {return this.params.set('direction', 'asc') };
    if (direction == 'asc') {return this.params.set('direction', 'desc') };
    if (direction == 'desc') {return this.params.delete('direction'); };
  }

}
