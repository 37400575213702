import 'chartjs-adapter-luxon';
import { DateTime } from 'luxon';
import Chart from 'chart.js/auto';
import ChartStyles from './chart_styles';

// Change styling based on whether the tick is major or minor
export let colorFunc = (ctx) => (ctx.tick.major ? ChartStyles.colors.blueGray : ChartStyles.axes.ticks.color)
export let weightFunc = (ctx) => (ctx.tick.major ? 'bold' : 'normal')
export let sizeFunc = (ctx) => (ctx.tick.major ? 14 : ChartStyles.axes.ticks.size)

// Get the timezone offset in minutes
export const getTimezoneOffset = _ => (DateTime.local().zone.offset(0) * -1)

// Make the first tick of each month/quarter major
export const afterBuildTicksFunc = ({ chart, ticks }) => {
  if (!ticks) return;

  ticks.forEach((tick) => {
    const interval = chart.options.scales.x.time.unit;
    const date = DateTime.fromMillis(tick.value).setZone(getTimezoneOffset());
    if ((interval === 'month' && date.month === 1) || (interval === 'quarter' && date.quarter === 1)) {
      tick.major = true;
    }
  })
}

// Tooltip callbacks
export const tooltipTitleCallback = ([ first, ..._rest ]) => {
  const interval = first.chart.options.scales.x.time.unit;
  const date = first.raw.x;
  if (interval === 'month') {
    return date.toFormat('MMMM yyyy');
  } else if (interval === 'quarter') {
    return `Q${date.toFormat('q - yyyy')}`;
  } else if (interval === 'year') {
    return date.toFormat('yyyy');
  }

  return ''; // Otherwise, don't show a title
}

export const tooltipLabelCallback = ({ dataset, raw }) => (`${dataset.label}: ${(numeral(raw.y).format('$0,0'))}`)
