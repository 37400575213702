import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['role', 'primary'];

  connect() {
    this.togglePrimary();
  }

  roleChanged() {
    this.togglePrimary();
  }

  togglePrimary() {
    const roleInput = this.roleTarget.querySelector("select");
    const selectedRole = roleInput.options[roleInput.selectedIndex].text;
    const primaryInput = this.primaryTarget.querySelector("#team_member_primary");
    if(selectedRole == "Client User") {
      this.primaryTarget.style = 'display: none;';
      primaryInput.checked = false;
    } else {
      this.primaryTarget.style = 'display: flex;';
    }
  }
}