import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['link']

  open(event) {
    event.preventDefault();
    const target = $(event.target).closest(this.linkTargets)[0];
    const url = target.dataset.url;
    const isFolder = (target.dataset.itemType === 'folder' || target.dataset.itemType == "folder-open");

    if(url === undefined) { return; }
    if(event.target.tagName === 'BUTTON' || event.target.tagName === 'I') { 
      event.stopImmediatePropagation(); 
    }

    if (isFolder) {
      window.location.href = url;
    } else {
      window.open(url, '_blank').focus();
    }
  }
}

export { Controller };
