import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    autoScroll: { type: Boolean, default: true }
  }

  connect() {
    this.isNearBottom = true
    this.scrollToBottom()
    this.observeContentChanges()
    this.observeScroll()

    this.element.addEventListener('turbo:frame-load', () => {
      if (this.isNearBottom) {
        this.scrollToBottom()
      }
    })
  }

  observeScroll() {
    this.element.addEventListener('scroll', () => {
      const threshold = 100 // pixels from bottom
      const position = this.element.scrollHeight - this.element.scrollTop - this.element.clientHeight
      this.isNearBottom = position < threshold
    })
  }

  scrollToBottom() {
    if (!this.element.lastElementChild) return

    // Store the parent scroll position
    const parentScrollTop = window.scrollY

    this.element.lastElementChild.scrollIntoView({
      behavior: 'auto',
      block: 'end'
    })

    // Restore the parent scroll position
    window.scrollTo({
      top: parentScrollTop,
      behavior: 'auto'
    })
  }

  observeContentChanges() {
    const observer = new MutationObserver(() => {
      if (this.isNearBottom && this.autoScrollValue) {
        this.scrollToBottom()
      }
    })

    observer.observe(this.element, {
      childList: true,
      subtree: true
    })
  }
}
