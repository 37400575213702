// Makes table rows clickable
// needs a url, turbp_frame as a data attribute

import { Controller } from "@hotwired/stimulus"
import * as bootstrap from "bootstrap"

export default class extends Controller {
  static targets = ['row']

  handleClick(event) {
    if(event.target.parentNode == this.rowTarget) {
      Turbo.visit(this.rowTarget.dataset.url, { frame: this.rowTarget.dataset.turboFrame, action:  this.rowTarget.dataset.turboAction })
    }
  }
}
