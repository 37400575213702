import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'viewTypeTimesheets',
    'viewTypeReports',
    'dateFilterType',
    'dateFilterTypeDisplay',
    'dateRange',
    'dateRangeStart',
    'dateRangeEnd',
    'timesheetScope',
    'showDeleted',
    'downloadLink',
    'account',
    'user',
    'search',
    'summaryData',
    'hoursSummary',
    'dollarsSummary',
    'percentageSummary',
    'metered',
    'synced'
  ];

  initialize() {
    this.timesheets = this.viewTypeTimesheetsTargets[0]
    this.reports = this.viewTypeReportsTargets[0]
    this.viewType = 'timesheets';
    this.timesheetScope = this.hasTimesheetScopeTarget && this.timesheetScopeTargets[0].checked
    this.dateRange = this.dateRangeTargets[0].value
    this.showDeleted = this.showDeletedTargets[0].checked
  }

  toggleViewType(e) {
    this.viewType = e.target.value

    if (this.viewType === 'timesheets') {
      this.reports.checked = false;
    } else if (this.viewType == 'reports') {
      this.timesheets.checked = false;
    }
  }

  toggleShowDeleted(e) {
    this.showDeleted = e.target.checked

    let searchParams = new URLSearchParams(window.location.search);
    if(this.showDeleted) {
      searchParams.set('show_deleted', 'true')
    }else{
      searchParams.delete('show_deleted')
    }
    searchParams.delete('page')
    searchParams.delete('per')
    window.location.search = searchParams.toString()
  }

  toggleTimesheetScope(e) {
    this.timesheetScope = e.target.checked

    let searchParams = new URLSearchParams(window.location.search);
    if(this.timesheetScope) {
      searchParams.set('scope', 'global')
    } else{
      searchParams.delete('scope')
    }
    searchParams.delete('page')
    searchParams.delete('per')
    searchParams.delete('user_id')
    searchParams.delete('account_id')

    window.location.search = searchParams.toString()
  }

  toggleBillable(e) {
    this.billable = e.target.checked

    let searchParams = new URLSearchParams(window.location.search);
    if(this.billable) {
      searchParams.set('billable', 'true')
    } else{
      searchParams.delete('billable')
    }
    searchParams.delete('page')
    searchParams.delete('per')

    window.location.search = searchParams.toString()
  }

  dateRangeChanged(e) {
    e.preventDefault()
    this.dateRange = this.dateRangeTargets[0].value
    let searchParams = new URLSearchParams(window.location.search);
    if(this.dateRange) {
      let range = this.dateRange.split(' - ')

      searchParams.set('start_date', range[0])
      searchParams.set('end_date', range[1])
      window.location.search = searchParams.toString()
    }
  }

  userChanged(e) {
    e.preventDefault()
    const user_ids = $(this.userTarget).val()
    let searchParams = new URLSearchParams(window.location.search);
    if(user_ids.length > 0) {
      searchParams.set('user_id', user_ids)
    } else {
      searchParams.delete('user_id')
    }
    searchParams.delete('page')
    searchParams.delete('per')
    window.location.search = searchParams.toString()
  }

  accountChanged(e) {
    e.preventDefault()
    const account_ids = $(this.accountTarget).val()
    let searchParams = new URLSearchParams(window.location.search);
    if(account_ids.length > 0) {
      searchParams.set('account_id', account_ids)
    } else {
      searchParams.delete('account_id')
    }
    searchParams.delete('page')
    searchParams.delete('per')
    window.location.search = searchParams.toString()
  }


  meteredChanged(e) {
    e.preventDefault()
    const metered = $(this.meteredTarget).val()
    let searchParams = new URLSearchParams(window.location.search);
    if(metered.length > 0) {
      searchParams.set('metered', metered)
    } else {
      searchParams.delete('metered')
    }
    searchParams.delete('page')
    searchParams.delete('per')
    window.location.search = searchParams.toString()
  }

  syncedChanged(e) {
    e.preventDefault()
    const synced = $(this.syncedTarget).val()
    let searchParams = new URLSearchParams(window.location.search);
    if(synced.length > 0) {
      searchParams.set('synced', synced)
    } else {
      searchParams.delete('synced')
    }
    if(synced == 'true') {
      searchParams.set('billable', 'true');
    }
    searchParams.delete('page')
    searchParams.delete('per')
    window.location.search = searchParams.toString()
  }
  searchChanged(e) {
    e.preventDefault()
    const query = this.searchTarget.value;
    if(e.key === "Enter") {
      let searchParams = new URLSearchParams(window.location.search);
      if(query.length > 0) {
        searchParams.set('q', query)
      } else {
        searchParams.delete('q')
        }

        searchParams.delete('page')
        searchParams.delete('per')
      window.location.search = searchParams.toString()
    }
  }

  download(url) {
    let searchParams = new URLSearchParams(window.location.search);
    window.location = url + '?' + searchParams.toString()
  }

  handleRadioToggle(list, value) {
    targets.forEach((t) => { 
      if (t.value !== value) {
        t.checked = false;
      }
    })
  }
}
